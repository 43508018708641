import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import PhoneInput from "react-phone-input-2"; // Import the PhoneInput component
import "react-phone-input-2/lib/style.css"; // Import the CSS for react-phone-input-2
import ReactQuill from "react-quill"; // ** START CHANGE FOR "Note input" --- Import ReactQuill **
import "react-quill/dist/quill.snow.css"; // ** Ensure to include the styling for ReactQuill **
import "./PublicFormPreview.css";
import { FaInfoCircle } from "react-icons/fa";

const PublicFormPreview = ({ formId, pipelineId }) => {
  // const { formId } = useParams();
  const [formStructure, setFormStructure] = useState(null);
  const [formValues, setFormValues] = useState({});
  const [errors, setErrors] = useState({});
  const [files, setFiles] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isDuplicateSubmission, setIsDuplicateSubmission] = useState(false);
  const [isError, setIsError] = useState(false);
  const [duplicateEmailError, setDuplicateEmailError] = useState(false); // For email duplicate check
  ///// for final application form
  useEffect(() => {
    // console.log('Received formId:', formId); // Log the received formId
    // rest of the effect...
  }, [formId]);
  useEffect(() => {
    if (!formId) {
      console.error("Form ID is undefined in PublicFormPreview");
      setIsError(true);
      return;
    }
    const fetchFormStructure = async () => {
      try {
        const response = await fetch(
          `https://mern3-9rgs.onrender.com/api/forms/general/${formId}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch form structure");
        }
        const data = await response.json();
        setFormStructure(data);
        setFormValues(
          data.fields.reduce((acc, field) => {
            acc[field.label] = field.type === "multiselect" ? [] : "";
            return acc;
          }, {})
        );
      } catch (error) {
        console.error("Error fetching form structure:", error);
        setIsError(true);
      }
    };
    fetchFormStructure();
  }, [formId]);

  const handleChange = (label, value) => {

            // Reset duplicate email error on change
            if (label === 'Email') {
              setDuplicateEmailError(false);
            }


    const field = formStructure.fields.find((f) => f.label === label);
    if (field && (!field.maxLength || value.length <= field.maxLength)) {
      setFormValues((prevValues) => ({
        ...prevValues,
        [label]: value,
      }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        [label]: field.required && value.length < field.minLength,
      }));
    }
            // Check if the email is already submitted
            if (label === 'Email') {
              checkDuplicateEmail(value);
            }


  };
    // Function to check if the email is already submitted
    const checkDuplicateEmail = async (email) => {
      try {
        const response = await fetch(`https://mern3-9rgs.onrender.com/api/forms/check-email`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ formId, pipelineId, email }), // Pass formId, pipelineId, and email to check
        });
        const data = await response.json();
        if (data.isDuplicate) {
          setDuplicateEmailError(true);
        }
      } catch (error) {
        console.error('Error checking email:', error);
      }
    };
    /** END CHANGE FOR fetch already submitted by this "Email" --- **/

  const handleCheckboxChange = (label, option) => {
    const field = formStructure.fields.find((f) => f.label === label);
    const selectedOptions = formValues[label];
    let newSelectedOptions = [];

    if (selectedOptions.includes(option)) {
      newSelectedOptions = selectedOptions.filter((item) => item !== option);
    } else if (selectedOptions.length < field.maxSelect) {
      newSelectedOptions = [...selectedOptions, option];
    }

    setFormValues((prevValues) => ({
      ...prevValues,
      [label]: newSelectedOptions,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [label]: field.required && newSelectedOptions.length === 0,
    }));
  };

  const handleFileChange = (label, file) => {
    if (file) {
      if (
        label === "Resume (PDF Format Only)" &&
        file.type !== "application/pdf"
      ) {
        alert("Please upload a PDF file.");
      } else if (
        label === "Upload Startup Logo (In PNG/JPG Format)" &&
        !["image/png", "image/jpeg"].includes(file.type)
      ) {
        alert("Please upload a PNG or JPG file.");
      } else {
        const currentDateTime = new Date().toLocaleString(); // Get the current date and time as a string

        setFiles((prevFiles) => {
          // Filter out any existing file with the same label
          const filteredFiles = prevFiles.filter((f) => f.label !== label);
          // Add the new file with the current date and time
          return [
            ...filteredFiles,
            { label, file, uploadedAt: currentDateTime },
          ];
        });
        setFormValues((prevValues) => ({
          ...prevValues,
          [label]: `${file.name} (uploaded at ${currentDateTime})`, // Include the upload time in the form values
        }));
        setErrors((prevErrors) => ({
          ...prevErrors,
          [label]: false,
        }));
      }
    }
  };

  const submitForm = async (formId, responses) => {
    const formData = new FormData();
    formData.append("formId", formId);
    formData.append("responses", JSON.stringify(responses));

    // Check for pipelineId before adding it
    if (pipelineId) {
      formData.append("pipelineId", pipelineId); // Add pipelineId to the request
    } else {
      console.error("pipelineId is undefined");
    }

    files.forEach((fileWrapper) => {
      formData.append("files", fileWrapper.file); // Corrected to append files
    });

    try {
      const response = await fetch(
        "https://mern3-9rgs.onrender.com/api/forms/public-form-submission",
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        console.error("Error submitting form:", errorData.error);
        if (errorData.error === "Form is already submitted for this email.") {
          setIsDuplicateSubmission(true);
        } else {
          setIsError(true);
        }
        throw new Error(errorData.error);
      } else {
        console.log("Form submitted successfully");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setIsError(true); // Correct error handling
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = {};
    let hasError = false;

    for (const field of formStructure.fields) {
      if (
        !formValues[field.label] ||
        formValues[field.label].length < field.minLength
      ) {
        newErrors[field.label] = true;
        hasError = true;
      }

      if (
        field.type === "multiselect" &&
        formValues[field.label].length === 0
      ) {
        newErrors[field.label] = true;
        hasError = true;
      }
    }

    if (hasError) {
      setErrors(newErrors);
      return;
    }

    try {
      await submitForm(formId, formValues);
      setIsSubmitted(true);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const handleClearForm = () => {
    setFormValues(
      formStructure.fields.reduce((acc, field) => {
        acc[field.label] = field.type === "multiselect" ? [] : "";
        return acc;
      }, {})
    );
    setFiles([]);
    setErrors({});
  };

  if (!formStructure) {
    return <div>Loading form...</div>;
  }

  if (isDuplicateSubmission) {
    return (
      <div className="response-page-publicformpreview">
        <div className="response-message-container-publicformpreview">
          <h1 className="response-title-publicformpreview">
            You've already responded
          </h1>
          <p className="response-text-publicformpreview">
            Thanks for submitting your contact info!
          </p>
          <p className="response-text-publicformpreview">
            You can only fill in this form once.
          </p>
        </div>
      </div>
    );
  }

  if (isError) {
    return (
      <div className="response-page-publicformpreview">
        <div className="response-message-container-publicformpreview">
          <h1 className="response-title-publicformpreview">
            You've already responded
          </h1>
          <p className="response-text-publicformpreview">
            Thanks for submitting your information!
          </p>
          <p className="response-text-publicformpreview">
            You can only fill in this form once.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="custom-background-publicformpreview">
      {isSubmitted ? (
        <div className="thank-you-message-fullscreen">
          <div className="thank-you-message-publicformpreview">
            <div className="icon-container-publicformpreview">
              <svg
                width="64"
                height="64"
                viewBox="0 0 64 64"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g fill="none" fillRule="evenodd">
                  <g transform="translate(1 1)" fillRule="nonzero">
                    <path
                      d="M48 6H16C11.589 6 8 9.589 8 14v32c0 4.411 3.589 8 8 8h32c4.411 0 8-3.589 8-8V14c0-4.411-3.589-8-8-8z"
                      fill="#EFF6FF"
                    />
                    <path
                      d="M56 0H8C3.589 0 0 3.589 0 8v48c0 4.411 3.589 8 8 8h48c4.411 0 8-3.589 8-8V8c0-4.411-3.589-8-8-8z"
                      fill="#EFF6FF"
                    />
                    <path
                      d="M44.293 20.293a1 1 0 00-1.414 0L24 39.172l-6.879-6.879a1 1 0 00-1.414 1.414l8 8a1 1 0 001.414 0l20-20a1 1 0 000-1.414z"
                      fill="#1E88E5"
                    />
                  </g>
                </g>
              </svg>
            </div>
            <h2>Thank you for your submission!</h2>
          </div>
        </div>
      ) : (
        <div className="custom-public-form-preview-publicformpreview">
          {/* <div className="header-publicformpreview">
            <img src="/navbar/drishtilogo.jpg" alt="Logo" className="logo-publicformpreview" />
            <div className="header-text-publicformpreview">
              <h1 className="company-title-publicformpreview">IITI DRISHTI CPS FOUNDATION</h1>
              <h2 className="company-subtitle-publicformpreview">IIT INDORE</h2>
            </div>
          </div> */}
          {/* <hr /> */}
          {/* <h2 className="custom-form-title-publicformpreview">{formStructure.title}</h2> */}
          <h2 className="custom-form-title-publicformpreview"> </h2>
          <form
            onSubmit={handleSubmit}
            className="custom-form-publicformpreview"
          >
            <div className="custom-form-row-publicformpreview">
              {formStructure.fields.map((field, index) => (
                <div
                  key={index}
                  className="custom-form-group-publicformpreview"
                >
                  <label className="custom-form-label-publicformpreview">
                    {field.label}
                  </label>
                  <div className="custom-form-input-container-publicformpreview">
                    {/* Container to hold the input directly below the label */}
                    {field.type === "select" ? (
                      <select
                        className="custom-form-input-publicformpreview"
                        value={formValues[field.label]}
                        onChange={(e) =>
                          handleChange(field.label, e.target.value)
                        }
                        required={field.required}
                      >
                        <option value="">Select...</option>
                        {field.options.map((option, optionIndex) => (
                          <option key={optionIndex} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    ) : field.type === "multiselect" ? (
                      field.options.map((option, optionIndex) => (
                        <div
                          key={optionIndex}
                          className="checkbox-group-publicformpreview"
                        >
                          <input
                            type="checkbox"
                            id={`${field.label}-${optionIndex}`}
                            value={option}
                            checked={formValues[field.label].includes(option)}
                            onChange={() =>
                              handleCheckboxChange(field.label, option)
                            }
                            disabled={
                              !formValues[field.label].includes(option) &&
                              formValues[field.label].length >= field.maxSelect
                            }
                          />
                          <label
                            htmlFor={`${field.label}-${optionIndex}`}
                            className="option-label-publicformpreview"
                            style={{ fontSize: "12px" }}
                          >
                            {option}
                          </label>
                        </div>
                      ))
                    ) : field.type === "radio" ? (
                      field.options.map((option, optionIndex) => (
                        <div
                          key={optionIndex}
                          className="radio-group-publicformpreview"
                        >
                          <input
                            type="radio"
                            id={`${field.label}-${optionIndex}`}
                            name={field.label}
                            value={option}
                            checked={formValues[field.label] === option}
                            onChange={(e) =>
                              handleChange(field.label, e.target.value)
                            }
                            required={field.required}
                          />
                          <label
                            htmlFor={`${field.label}-${optionIndex}`}
                            className="option-label-publicformpreview-radio"
                            style={{ fontSize: "14px" }}
                          >
                            {option}
                          </label>
                        </div>
                      ))
                    ) : field.type === "note" ? ( // ** START CHANGE FOR "Note ReactQuill input" --- **
                      <div className="custom-note-container-publicformpreview">
                        {" "}
                        {/* Container for ReactQuill to add spacing */}
                        <ReactQuill
                          value={formValues[field.label]}
                          onChange={(value) => handleChange(field.label, value)}
                        />
                      </div> // ** END CHANGE FOR "Note ReactQuill input" --- **
                    ) : field.type === "file" ? (
                      <input
                        className="custom-form-input-publicformpreview"
                        type="file"
                        onChange={(e) =>
                          handleFileChange(field.label, e.target.files[0])
                        }
                        required={field.required}
                      />
                    ) : field.type === "phone" ? (
                      // Change applied to set the default country to India (+91)
                      <PhoneInput
                        country={"in"} // <-- Default country changed to 'in' (India)
                        value={formValues[field.label]}
                        onChange={(value) => handleChange(field.label, value)}
                        inputClass="custom-form-input-publicformpreview" // Match the class name
                        required={field.required}
                      />
                    ) : (
                      <input
                        className={`custom-form-input-publicformpreview ${
                          errors[field.label] ? "error-publicformpreview" : ""
                        }`}
                        type={field.type}
                        placeholder={field.placeholder}
                        value={formValues[field.label]}
                        onChange={(e) =>
                          handleChange(field.label, e.target.value)
                        }
                        required={field.required}
                        maxLength={field.maxLength || undefined}
                      />
                    )}
                  </div>
                  {/* START CHANGE FOR fetch already submitted by this "Email" --- */}
                   {/* Display duplicate email error message */}
                   {field.label === 'Email' && duplicateEmailError && (
                    <div className="error-message-publicformpreview">
                      <FaInfoCircle className="error-icon-publicformpreview" /> You have already submitted the form using this email ID.
                    </div>
                  )}
                  {/* END CHANGE FOR fetch already submitted by this "Email" --- */}

                  {errors[field.label] && (
                    <div className="error-message-publicformpreview">
                      <FaInfoCircle className="error-icon-publicformpreview" />{" "}
                      This field is required
                    </div>
                  )}
                  {(field.maxLength || field.minLength) &&
                    field.label !== "Contact Number" &&
                    field.label !== "Startup team size" && (
                      <div className="character-limit-publicformpreview">
                        {field.maxLength &&
                          `${
                            field.maxLength -
                            (formValues[field.label]?.length || 0)
                          } characters remaining`}
                        {field.minLength &&
                          (formValues[field.label]?.length || 0) <
                            field.minLength &&
                          ` (Min: ${field.minLength} characters)`}
                      </div>
                    )}
                </div>
              ))}
            </div>
            <div className="form-buttons-publicformpreview">
              <button
                type="submit"
                className="custom-form-submit-button-publicformpreviewfinalsave"
                style={{
                  width: "22%",
                  border: "none",
                  borderRadius: "4px",
                  fontSize: "13px",
                  padding: "10px",
                }}
              >
                Submit
              </button>
              <button
                type="button"
                className="custom-form-clear-button-publicformpreview"
                onClick={() => handleClearForm()}
              >
                Clear Form
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default PublicFormPreview;











/////////////before automatically fetch User Email in input


// import React, { useState, useEffect } from "react";
// import { useParams } from "react-router-dom";
// import PhoneInput from "react-phone-input-2"; // Import the PhoneInput component
// import "react-phone-input-2/lib/style.css"; // Import the CSS for react-phone-input-2
// import ReactQuill from "react-quill"; // ** START CHANGE FOR "Note input" --- Import ReactQuill **
// import "react-quill/dist/quill.snow.css"; // ** Ensure to include the styling for ReactQuill **
// import "./PublicFormPreview.css";
// import { FaInfoCircle } from "react-icons/fa";

// const PublicFormPreview = ({ formId, pipelineId }) => {
//   // const { formId } = useParams();
//   const [formStructure, setFormStructure] = useState(null);
//   const [formValues, setFormValues] = useState({});
//   const [errors, setErrors] = useState({});
//   const [files, setFiles] = useState([]);
//   const [isSubmitted, setIsSubmitted] = useState(false);
//   const [isDuplicateSubmission, setIsDuplicateSubmission] = useState(false);
//   const [isError, setIsError] = useState(false);
//   ///// for final application form
//   useEffect(() => {
//     // console.log('Received formId:', formId); // Log the received formId
//     // rest of the effect...
//   }, [formId]);
//   useEffect(() => {
//     if (!formId) {
//       console.error("Form ID is undefined in PublicFormPreview");
//       setIsError(true);
//       return;
//     }
//     const fetchFormStructure = async () => {
//       try {
//         const response = await fetch(
//           `https://mern3-9rgs.onrender.com/api/forms/general/${formId}`
//         );
//         if (!response.ok) {
//           throw new Error("Failed to fetch form structure");
//         }
//         const data = await response.json();
//         setFormStructure(data);
//         setFormValues(
//           data.fields.reduce((acc, field) => {
//             acc[field.label] = field.type === "multiselect" ? [] : "";
//             return acc;
//           }, {})
//         );
//       } catch (error) {
//         console.error("Error fetching form structure:", error);
//         setIsError(true);
//       }
//     };
//     fetchFormStructure();
//   }, [formId]);

//   const handleChange = (label, value) => {
//     const field = formStructure.fields.find((f) => f.label === label);
//     if (field && (!field.maxLength || value.length <= field.maxLength)) {
//       setFormValues((prevValues) => ({
//         ...prevValues,
//         [label]: value,
//       }));
//       setErrors((prevErrors) => ({
//         ...prevErrors,
//         [label]: field.required && value.length < field.minLength,
//       }));
//     }
//   };

//   const handleCheckboxChange = (label, option) => {
//     const field = formStructure.fields.find((f) => f.label === label);
//     const selectedOptions = formValues[label];
//     let newSelectedOptions = [];

//     if (selectedOptions.includes(option)) {
//       newSelectedOptions = selectedOptions.filter((item) => item !== option);
//     } else if (selectedOptions.length < field.maxSelect) {
//       newSelectedOptions = [...selectedOptions, option];
//     }

//     setFormValues((prevValues) => ({
//       ...prevValues,
//       [label]: newSelectedOptions,
//     }));

//     setErrors((prevErrors) => ({
//       ...prevErrors,
//       [label]: field.required && newSelectedOptions.length === 0,
//     }));
//   };

//   const handleFileChange = (label, file) => {
//     if (file) {
//       if (
//         label === "Resume (PDF Format Only)" &&
//         file.type !== "application/pdf"
//       ) {
//         alert("Please upload a PDF file.");
//       } else if (
//         label === "Upload Startup Logo (In PNG/JPG Format)" &&
//         !["image/png", "image/jpeg"].includes(file.type)
//       ) {
//         alert("Please upload a PNG or JPG file.");
//       } else {
//         const currentDateTime = new Date().toLocaleString(); // Get the current date and time as a string

//         setFiles((prevFiles) => {
//           // Filter out any existing file with the same label
//           const filteredFiles = prevFiles.filter((f) => f.label !== label);
//           // Add the new file with the current date and time
//           return [
//             ...filteredFiles,
//             { label, file, uploadedAt: currentDateTime },
//           ];
//         });
//         setFormValues((prevValues) => ({
//           ...prevValues,
//           [label]: `${file.name} (uploaded at ${currentDateTime})`, // Include the upload time in the form values
//         }));
//         setErrors((prevErrors) => ({
//           ...prevErrors,
//           [label]: false,
//         }));
//       }
//     }
//   };

//   const submitForm = async (formId, responses) => {
//     const formData = new FormData();
//     formData.append("formId", formId);
//     formData.append("responses", JSON.stringify(responses));

//     // Check for pipelineId before adding it
//     if (pipelineId) {
//       formData.append("pipelineId", pipelineId); // Add pipelineId to the request
//     } else {
//       console.error("pipelineId is undefined");
//     }

//     files.forEach((fileWrapper) => {
//       formData.append("files", fileWrapper.file); // Corrected to append files
//     });

//     try {
//       const response = await fetch(
//         "https://mern3-9rgs.onrender.com/api/forms/public-form-submission",
//         {
//           method: "POST",
//           body: formData,
//         }
//       );

//       if (!response.ok) {
//         const errorData = await response.json();
//         console.error("Error submitting form:", errorData.error);
//         if (errorData.error === "Form is already submitted for this email.") {
//           setIsDuplicateSubmission(true);
//         } else {
//           setIsError(true);
//         }
//         throw new Error(errorData.error);
//       } else {
//         console.log("Form submitted successfully");
//       }
//     } catch (error) {
//       console.error("Error submitting form:", error);
//       setIsError(true); // Correct error handling
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     const newErrors = {};
//     let hasError = false;

//     for (const field of formStructure.fields) {
//       if (
//         !formValues[field.label] ||
//         formValues[field.label].length < field.minLength
//       ) {
//         newErrors[field.label] = true;
//         hasError = true;
//       }

//       if (
//         field.type === "multiselect" &&
//         formValues[field.label].length === 0
//       ) {
//         newErrors[field.label] = true;
//         hasError = true;
//       }
//     }

//     if (hasError) {
//       setErrors(newErrors);
//       return;
//     }

//     try {
//       await submitForm(formId, formValues);
//       setIsSubmitted(true);
//     } catch (error) {
//       console.error("Error submitting form:", error);
//     }
//   };

//   const handleClearForm = () => {
//     setFormValues(
//       formStructure.fields.reduce((acc, field) => {
//         acc[field.label] = field.type === "multiselect" ? [] : "";
//         return acc;
//       }, {})
//     );
//     setFiles([]);
//     setErrors({});
//   };

//   if (!formStructure) {
//     return <div>Loading form...</div>;
//   }

//   if (isDuplicateSubmission) {
//     return (
//       <div className="response-page-publicformpreview">
//         <div className="response-message-container-publicformpreview">
//           <h1 className="response-title-publicformpreview">
//             You've already responded
//           </h1>
//           <p className="response-text-publicformpreview">
//             Thanks for submitting your contact info!
//           </p>
//           <p className="response-text-publicformpreview">
//             You can only fill in this form once.
//           </p>
//         </div>
//       </div>
//     );
//   }

//   if (isError) {
//     return (
//       <div className="response-page-publicformpreview">
//         <div className="response-message-container-publicformpreview">
//           <h1 className="response-title-publicformpreview">
//             You've already responded
//           </h1>
//           <p className="response-text-publicformpreview">
//             Thanks for submitting your information!
//           </p>
//           <p className="response-text-publicformpreview">
//             You can only fill in this form once.
//           </p>
//         </div>
//       </div>
//     );
//   }

//   return (
//     <div className="custom-background-publicformpreview">
//       {isSubmitted ? (
//         <div className="thank-you-message-fullscreen">
//           <div className="thank-you-message-publicformpreview">
//             <div className="icon-container-publicformpreview">
//               <svg
//                 width="64"
//                 height="64"
//                 viewBox="0 0 64 64"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <g fill="none" fillRule="evenodd">
//                   <g transform="translate(1 1)" fillRule="nonzero">
//                     <path
//                       d="M48 6H16C11.589 6 8 9.589 8 14v32c0 4.411 3.589 8 8 8h32c4.411 0 8-3.589 8-8V14c0-4.411-3.589-8-8-8z"
//                       fill="#EFF6FF"
//                     />
//                     <path
//                       d="M56 0H8C3.589 0 0 3.589 0 8v48c0 4.411 3.589 8 8 8h48c4.411 0 8-3.589 8-8V8c0-4.411-3.589-8-8-8z"
//                       fill="#EFF6FF"
//                     />
//                     <path
//                       d="M44.293 20.293a1 1 0 00-1.414 0L24 39.172l-6.879-6.879a1 1 0 00-1.414 1.414l8 8a1 1 0 001.414 0l20-20a1 1 0 000-1.414z"
//                       fill="#1E88E5"
//                     />
//                   </g>
//                 </g>
//               </svg>
//             </div>
//             <h2>Thank you for your submission!</h2>
//           </div>
//         </div>
//       ) : (
//         <div className="custom-public-form-preview-publicformpreview">
//           {/* <div className="header-publicformpreview">
//             <img src="/navbar/drishtilogo.jpg" alt="Logo" className="logo-publicformpreview" />
//             <div className="header-text-publicformpreview">
//               <h1 className="company-title-publicformpreview">IITI DRISHTI CPS FOUNDATION</h1>
//               <h2 className="company-subtitle-publicformpreview">IIT INDORE</h2>
//             </div>
//           </div> */}
//           {/* <hr /> */}
//           {/* <h2 className="custom-form-title-publicformpreview">{formStructure.title}</h2> */}
//           <h2 className="custom-form-title-publicformpreview"> </h2>
//           <form
//             onSubmit={handleSubmit}
//             className="custom-form-publicformpreview"
//           >
//             <div className="custom-form-row-publicformpreview">
//               {formStructure.fields.map((field, index) => (
//                 <div
//                   key={index}
//                   className="custom-form-group-publicformpreview"
//                 >
//                   <label className="custom-form-label-publicformpreview">
//                     {field.label}
//                   </label>
//                   <div className="custom-form-input-container-publicformpreview">
//                     {/* Container to hold the input directly below the label */}
//                     {field.type === "select" ? (
//                       <select
//                         className="custom-form-input-publicformpreview"
//                         value={formValues[field.label]}
//                         onChange={(e) =>
//                           handleChange(field.label, e.target.value)
//                         }
//                         required={field.required}
//                       >
//                         <option value="">Select...</option>
//                         {field.options.map((option, optionIndex) => (
//                           <option key={optionIndex} value={option}>
//                             {option}
//                           </option>
//                         ))}
//                       </select>
//                     ) : field.type === "multiselect" ? (
//                       field.options.map((option, optionIndex) => (
//                         <div
//                           key={optionIndex}
//                           className="checkbox-group-publicformpreview"
//                         >
//                           <input
//                             type="checkbox"
//                             id={`${field.label}-${optionIndex}`}
//                             value={option}
//                             checked={formValues[field.label].includes(option)}
//                             onChange={() =>
//                               handleCheckboxChange(field.label, option)
//                             }
//                             disabled={
//                               !formValues[field.label].includes(option) &&
//                               formValues[field.label].length >= field.maxSelect
//                             }
//                           />
//                           <label
//                             htmlFor={`${field.label}-${optionIndex}`}
//                             className="option-label-publicformpreview"
//                             style={{ fontSize: "12px" }}
//                           >
//                             {option}
//                           </label>
//                         </div>
//                       ))
//                     ) : field.type === "radio" ? (
//                       field.options.map((option, optionIndex) => (
//                         <div
//                           key={optionIndex}
//                           className="radio-group-publicformpreview"
//                         >
//                           <input
//                             type="radio"
//                             id={`${field.label}-${optionIndex}`}
//                             name={field.label}
//                             value={option}
//                             checked={formValues[field.label] === option}
//                             onChange={(e) =>
//                               handleChange(field.label, e.target.value)
//                             }
//                             required={field.required}
//                           />
//                           <label
//                             htmlFor={`${field.label}-${optionIndex}`}
//                             className="option-label-publicformpreview-radio"
//                             style={{ fontSize: "14px" }}
//                           >
//                             {option}
//                           </label>
//                         </div>
//                       ))
//                     ) : field.type === "note" ? ( // ** START CHANGE FOR "Note ReactQuill input" --- **
//                       <div className="custom-note-container-publicformpreview">
//                         {" "}
//                         {/* Container for ReactQuill to add spacing */}
//                         <ReactQuill
//                           value={formValues[field.label]}
//                           onChange={(value) => handleChange(field.label, value)}
//                         />
//                       </div> // ** END CHANGE FOR "Note ReactQuill input" --- **
//                     ) : field.type === "file" ? (
//                       <input
//                         className="custom-form-input-publicformpreview"
//                         type="file"
//                         onChange={(e) =>
//                           handleFileChange(field.label, e.target.files[0])
//                         }
//                         required={field.required}
//                       />
//                     ) : field.type === "phone" ? (
//                       // Change applied to set the default country to India (+91)
//                       <PhoneInput
//                         country={"in"} // <-- Default country changed to 'in' (India)
//                         value={formValues[field.label]}
//                         onChange={(value) => handleChange(field.label, value)}
//                         inputClass="custom-form-input-publicformpreview" // Match the class name
//                         required={field.required}
//                       />
//                     ) : (
//                       <input
//                         className={`custom-form-input-publicformpreview ${
//                           errors[field.label] ? "error-publicformpreview" : ""
//                         }`}
//                         type={field.type}
//                         placeholder={field.placeholder}
//                         value={formValues[field.label]}
//                         onChange={(e) =>
//                           handleChange(field.label, e.target.value)
//                         }
//                         required={field.required}
//                         maxLength={field.maxLength || undefined}
//                       />
//                     )}
//                   </div>

//                   {errors[field.label] && (
//                     <div className="error-message-publicformpreview">
//                       <FaInfoCircle className="error-icon-publicformpreview" />{" "}
//                       This field is required
//                     </div>
//                   )}
//                   {(field.maxLength || field.minLength) &&
//                     field.label !== "Contact Number" &&
//                     field.label !== "Startup team size" && (
//                       <div className="character-limit-publicformpreview">
//                         {field.maxLength &&
//                           `${
//                             field.maxLength -
//                             (formValues[field.label]?.length || 0)
//                           } characters remaining`}
//                         {field.minLength &&
//                           (formValues[field.label]?.length || 0) <
//                             field.minLength &&
//                           ` (Min: ${field.minLength} characters)`}
//                       </div>
//                     )}
//                 </div>
//               ))}
//             </div>
//             <div className="form-buttons-publicformpreview">
//               <button
//                 type="submit"
//                 className="custom-form-submit-button-publicformpreviewfinalsave"
//                 style={{
//                   width: "22%",
//                   border: "none",
//                   borderRadius: "4px",
//                   fontSize: "13px",
//                   padding: "10px",
//                 }}
//               >
//                 Submit
//               </button>
//               <button
//                 type="button"
//                 className="custom-form-clear-button-publicformpreview"
//                 onClick={() => handleClearForm()}
//               >
//                 Clear Form
//               </button>
//             </div>
//           </form>
//         </div>
//       )}
//     </div>
//   );
// };

// export default PublicFormPreview;

//////////17 09

// import React, { useState, useEffect } from "react";
// import { useParams } from "react-router-dom";
// import PhoneInput from "react-phone-input-2"; // Import the PhoneInput component
// import "react-phone-input-2/lib/style.css"; // Import the CSS for react-phone-input-2
// import "./PublicFormPreview.css";
// import { FaInfoCircle } from "react-icons/fa";

// const PublicFormPreview = ({ formId, pipelineId }) => {
//   // const { formId } = useParams();
//   const [formStructure, setFormStructure] = useState(null);
//   const [formValues, setFormValues] = useState({});
//   const [errors, setErrors] = useState({});
//   const [files, setFiles] = useState([]);
//   const [isSubmitted, setIsSubmitted] = useState(false);
//   const [isDuplicateSubmission, setIsDuplicateSubmission] = useState(false);
//   const [isError, setIsError] = useState(false);

//   // useEffect(() => {
//   //   const fetchFormStructure = async () => {
//   //     try {
//   //       const response = await fetch(`http://localhost:5000/api/forms/general/${formId}`);
//   //       const data = await response.json();
//   //       setFormStructure(data);
//   //       setFormValues(
//   //         data.fields.reduce((acc, field) => {
//   //           acc[field.label] = field.type === 'multiselect' ? [] : '';
//   //           return acc;
//   //         }, {})
//   //       );
//   //     } catch (error) {
//   //       console.error('Error fetching shared form:', error);
//   //     }
//   //   };

//   //   fetchFormStructure();
//   // }, [formId]);

//   ///// for final application form
//   useEffect(() => {
//     console.log("Received formId:", formId); // Log the received formId
//     // rest of the effect...
//   }, [formId]);
//   useEffect(() => {
//     if (!formId) {
//       console.error("Form ID is undefined in PublicFormPreview");
//       setIsError(true);
//       return;
//     }
//     const fetchFormStructure = async () => {
//       try {
//         const response = await fetch(
//           `https://mern3-9rgs.onrender.com/api/forms/general/${formId}`
//         );
//         if (!response.ok) {
//           throw new Error("Failed to fetch form structure");
//         }
//         const data = await response.json();
//         setFormStructure(data);
//         setFormValues(
//           data.fields.reduce((acc, field) => {
//             acc[field.label] = field.type === "multiselect" ? [] : "";
//             return acc;
//           }, {})
//         );
//       } catch (error) {
//         console.error("Error fetching form structure:", error);
//         setIsError(true);
//       }
//     };
//     fetchFormStructure();
//   }, [formId]);

//   const handleChange = (label, value) => {
//     const field = formStructure.fields.find((f) => f.label === label);
//     if (field && (!field.maxLength || value.length <= field.maxLength)) {
//       setFormValues((prevValues) => ({
//         ...prevValues,
//         [label]: value,
//       }));
//       setErrors((prevErrors) => ({
//         ...prevErrors,
//         [label]: field.required && value.length < field.minLength,
//       }));
//     }
//   };

//   const handleCheckboxChange = (label, option) => {
//     const field = formStructure.fields.find((f) => f.label === label);
//     const selectedOptions = formValues[label];
//     let newSelectedOptions = [];

//     if (selectedOptions.includes(option)) {
//       newSelectedOptions = selectedOptions.filter((item) => item !== option);
//     } else if (selectedOptions.length < field.maxSelect) {
//       newSelectedOptions = [...selectedOptions, option];
//     }

//     setFormValues((prevValues) => ({
//       ...prevValues,
//       [label]: newSelectedOptions,
//     }));

//     setErrors((prevErrors) => ({
//       ...prevErrors,
//       [label]: field.required && newSelectedOptions.length === 0,
//     }));
//   };

//   const handleFileChange = (label, file) => {
//     if (file) {
//       if (
//         label === "Resume (PDF Format Only)" &&
//         file.type !== "application/pdf"
//       ) {
//         alert("Please upload a PDF file.");
//       } else if (
//         label === "Upload Startup Logo (In PNG/JPG Format)" &&
//         !["image/png", "image/jpeg"].includes(file.type)
//       ) {
//         alert("Please upload a PNG or JPG file.");
//       } else {
//         const currentDateTime = new Date().toLocaleString(); // Get the current date and time as a string

//         setFiles((prevFiles) => {
//           // Filter out any existing file with the same label
//           const filteredFiles = prevFiles.filter((f) => f.label !== label);
//           // Add the new file with the current date and time
//           return [
//             ...filteredFiles,
//             { label, file, uploadedAt: currentDateTime },
//           ];
//         });
//         setFormValues((prevValues) => ({
//           ...prevValues,
//           [label]: `${file.name} (uploaded at ${currentDateTime})`, // Include the upload time in the form values
//         }));
//         setErrors((prevErrors) => ({
//           ...prevErrors,
//           [label]: false,
//         }));
//       }
//     }
//   };

//   const submitForm = async (formId, responses) => {
//     const formData = new FormData();
//     formData.append("formId", formId);
//     formData.append("responses", JSON.stringify(responses));

//     // Check for pipelineId before adding it
//     if (pipelineId) {
//       formData.append("pipelineId", pipelineId); // Add pipelineId to the request
//     } else {
//       console.error("pipelineId is undefined");
//     }

//     files.forEach((fileWrapper) => {
//       formData.append("files", fileWrapper.file); // Corrected to append files
//     });

//     try {
//       const response = await fetch(
//         "https://mern3-9rgs.onrender.com/api/forms/public-form-submission",
//         {
//           method: "POST",
//           body: formData,
//         }
//       );

//       if (!response.ok) {
//         const errorData = await response.json();
//         console.error("Error submitting form:", errorData.error);
//         if (errorData.error === "Form is already submitted for this email.") {
//           setIsDuplicateSubmission(true);
//         } else {
//           setIsError(true);
//         }
//         throw new Error(errorData.error);
//       } else {
//         console.log("Form submitted successfully");
//       }
//     } catch (error) {
//       console.error("Error submitting form:", error);
//       setIsError(true); // Correct error handling
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     const newErrors = {};
//     let hasError = false;

//     for (const field of formStructure.fields) {
//       if (
//         !formValues[field.label] ||
//         formValues[field.label].length < field.minLength
//       ) {
//         newErrors[field.label] = true;
//         hasError = true;
//       }

//       if (
//         field.type === "multiselect" &&
//         formValues[field.label].length === 0
//       ) {
//         newErrors[field.label] = true;
//         hasError = true;
//       }
//     }

//     if (hasError) {
//       setErrors(newErrors);
//       return;
//     }

//     try {
//       await submitForm(formId, formValues);
//       setIsSubmitted(true);
//     } catch (error) {
//       console.error("Error submitting form:", error);
//     }
//   };

//   const handleClearForm = () => {
//     setFormValues(
//       formStructure.fields.reduce((acc, field) => {
//         acc[field.label] = field.type === "multiselect" ? [] : "";
//         return acc;
//       }, {})
//     );
//     setFiles([]);
//     setErrors({});
//   };

//   if (!formStructure) {
//     return <div>Loading form...</div>;
//   }

//   if (isDuplicateSubmission) {
//     return (
//       <div className="response-page-publicformpreview">
//         <div className="response-message-container-publicformpreview">
//           <h1 className="response-title-publicformpreview">
//             You've already responded
//           </h1>
//           <p className="response-text-publicformpreview">
//             Thanks for submitting your contact info!
//           </p>
//           <p className="response-text-publicformpreview">
//             You can only fill in this form once.
//           </p>
//         </div>
//       </div>
//     );
//   }

//   if (isError) {
//     return (
//       <div className="response-page-publicformpreview">
//         <div className="response-message-container-publicformpreview">
//           <h1 className="response-title-publicformpreview">
//             You've already responded
//           </h1>
//           <p className="response-text-publicformpreview">
//             Thanks for submitting your information!
//           </p>
//           <p className="response-text-publicformpreview">
//             You can only fill in this form once.
//           </p>
//         </div>
//       </div>
//     );
//   }

//   return (
//     <div className="custom-background-publicformpreview">
//       {isSubmitted ? (
//         <div className="thank-you-message-publicformpreview">
//           <div className="icon-container-publicformpreview">
//             <svg
//               width="64"
//               height="64"
//               viewBox="0 0 64 64"
//               xmlns="http://www.w3.org/2000/svg"
//             >
//               <g fill="none" fillRule="evenodd">
//                 <g transform="translate(1 1)" fillRule="nonzero">
//                   <path
//                     d="M48 6H16C11.589 6 8 9.589 8 14v32c0 4.411 3.589 8 8 8h32c4.411 0 8-3.589 8-8V14c0-4.411-3.589-8-8-8z"
//                     fill="#EFF6FF"
//                   />
//                   <path
//                     d="M56 0H8C3.589 0 0 3.589 0 8v48c0 4.411 3.589 8 8 8h48c4.411 0 8-3.589 8-8V8c0-4.411-3.589-8-8-8z"
//                     fill="#EFF6FF"
//                   />
//                   <path
//                     d="M44.293 20.293a1 1 0 00-1.414 0L24 39.172l-6.879-6.879a1 1 0 00-1.414 1.414l8 8a1 1 0 001.414 0l20-20a1 1 0 000-1.414z"
//                     fill="#1E88E5"
//                   />
//                 </g>
//               </g>
//             </svg>
//           </div>
//           <h2>Thank you for your submission!</h2>
//         </div>
//       ) : (
//         <div className="custom-public-form-preview-publicformpreview">
//           {/* <div className="header-publicformpreview">
//             <img
//               src="/navbar/drishtilogo.jpg"
//               alt="Logo"
//               className="logo-publicformpreview"
//             />
//             <div className="header-text-publicformpreview">
//               <h1 className="company-title-publicformpreview">
//                 IITI DRISHTI CPS FOUNDATION
//               </h1>
//               <h2 className="company-subtitle-publicformpreview">IIT INDORE</h2>
//             </div>
//           </div>
//           <hr /> */}
//           {/* <h2 className="custom-form-title-publicformpreview">{formStructure.title}</h2> */}
//           <h2 className="custom-form-title-publicformpreview"> </h2>
//           <form
//             onSubmit={handleSubmit}
//             className="custom-form-publicformpreview"
//           >
//             <div className="custom-form-row-publicformpreview">
//               {formStructure.fields.map((field, index) => (
//                 <div
//                   key={index}
//                   className="custom-form-group-publicformpreview"
//                 >
//                   <label className="custom-form-label-publicformpreview">
//                     {field.label}
//                   </label>
//                   {field.type === "select" ? (
//                     <select
//                       className="custom-form-input-publicformpreview"
//                       value={formValues[field.label]}
//                       onChange={(e) =>
//                         handleChange(field.label, e.target.value)
//                       }
//                       required={field.required}
//                     >
//                       <option value="">Select...</option>
//                       {field.options.map((option, optionIndex) => (
//                         <option key={optionIndex} value={option}>
//                           {option}
//                         </option>
//                       ))}
//                     </select>
//                   ) : field.type === "multiselect" ? (
//                     field.options.map((option, optionIndex) => (
//                       <div
//                         key={optionIndex}
//                         className="checkbox-group-publicformpreview"
//                       >
//                         <input
//                           type="checkbox"
//                           id={`${field.label}-${optionIndex}`}
//                           value={option}
//                           checked={formValues[field.label].includes(option)}
//                           onChange={() =>
//                             handleCheckboxChange(field.label, option)
//                           }
//                           disabled={
//                             !formValues[field.label].includes(option) &&
//                             formValues[field.label].length >= field.maxSelect
//                           }
//                         />
//                         <label
//                           htmlFor={`${field.label}-${optionIndex}`}
//                           className="option-label-publicformpreview"
//                           style={{ fontSize: "12px" }}
//                         >
//                           {option}
//                         </label>
//                       </div>
//                     ))
//                   ) : field.type === "radio" ? (
//                     field.options.map((option, optionIndex) => (
//                       <div
//                         key={optionIndex}
//                         className="radio-group-publicformpreview"
//                       >
//                         <input
//                           type="radio"
//                           id={`${field.label}-${optionIndex}`}
//                           name={field.label}
//                           value={option}
//                           checked={formValues[field.label] === option}
//                           onChange={(e) =>
//                             handleChange(field.label, e.target.value)
//                           }
//                           required={field.required}
//                         />
//                         <label
//                           htmlFor={`${field.label}-${optionIndex}`}
//                           className="option-label-publicformpreview-radio"
//                           style={{ fontSize: "14px" }}
//                         >
//                           {option}
//                         </label>
//                       </div>
//                     ))
//                   ) : field.type === "file" ? (
//                     <input
//                       className="custom-form-input-publicformpreview"
//                       type="file"
//                       onChange={(e) =>
//                         handleFileChange(field.label, e.target.files[0])
//                       }
//                       required={field.required}
//                     />
//                   ) : field.type === "phone" ? (
//                     // Change applied to set the default country to India (+91)
//                     <PhoneInput
//                       country={"in"} // <-- Default country changed to 'in' (India)
//                       value={formValues[field.label]}
//                       onChange={(value) => handleChange(field.label, value)}
//                       inputClass="custom-form-input-publicformpreview" // Match the class name
//                       required={field.required}
//                     />
//                   ) : (
//                     <input
//                       className={`custom-form-input-publicformpreview ${
//                         errors[field.label] ? "error-publicformpreview" : ""
//                       }`}
//                       type={field.type}
//                       placeholder={field.placeholder}
//                       value={formValues[field.label]}
//                       onChange={(e) =>
//                         handleChange(field.label, e.target.value)
//                       }
//                       required={field.required}
//                       maxLength={field.maxLength || undefined}
//                     />
//                   )}
//                   {errors[field.label] && (
//                     <div className="error-message-publicformpreview">
//                       <FaInfoCircle className="error-icon-publicformpreview" />{" "}
//                       This field is required
//                     </div>
//                   )}
//                   {(field.maxLength || field.minLength) &&
//                     field.label !== "Contact Number" &&
//                     field.label !== "Startup team size" && (
//                       <div className="character-limit-publicformpreview">
//                         {field.maxLength &&
//                           `${
//                             field.maxLength -
//                             (formValues[field.label]?.length || 0)
//                           } characters remaining`}
//                         {field.minLength &&
//                           (formValues[field.label]?.length || 0) <
//                             field.minLength &&
//                           ` (Min: ${field.minLength} characters)`}
//                       </div>
//                     )}
//                 </div>
//               ))}
//             </div>
//             <div className="form-buttons-publicformpreview">
//               <button
//                 type="submit"
//                 className="custom-form-submit-button-publicformpreviewfinalsave"
//                 style={{
//                   width: "22%",
//                   border: "none",
//                   borderRadius: "4px",
//                   fontSize: "13px",
//                   padding: "10px",
//                 }}
//               >
//                 Submit
//               </button>
//               <button
//                 type="button"
//                 className="custom-form-clear-button-publicformpreview"
//                 onClick={() => handleClearForm()}
//               >
//                 Clear Form
//               </button>
//             </div>
//           </form>
//         </div>
//       )}
//     </div>
//   );
// };

// export default PublicFormPreview;

////////bef fetch poster 13 09

// import React, { useState, useEffect } from "react";
// import { useParams } from "react-router-dom";
// import PhoneInput from "react-phone-input-2"; // Import the PhoneInput component
// import "react-phone-input-2/lib/style.css"; // Import the CSS for react-phone-input-2
// import "./PublicFormPreview.css";
// import { FaInfoCircle } from "react-icons/fa";

// const PublicFormPreview = ({ formId, pipelineId }) => {
//   // const { formId } = useParams();
//   const [formStructure, setFormStructure] = useState(null);
//   const [formValues, setFormValues] = useState({});
//   const [errors, setErrors] = useState({});
//   const [files, setFiles] = useState([]);
//   const [isSubmitted, setIsSubmitted] = useState(false);
//   const [isDuplicateSubmission, setIsDuplicateSubmission] = useState(false);
//   const [isError, setIsError] = useState(false);

//   // useEffect(() => {
//   //   const fetchFormStructure = async () => {
//   //     try {
//   //       const response = await fetch(`http://localhost:5000/api/forms/general/${formId}`);
//   //       const data = await response.json();
//   //       setFormStructure(data);
//   //       setFormValues(
//   //         data.fields.reduce((acc, field) => {
//   //           acc[field.label] = field.type === 'multiselect' ? [] : '';
//   //           return acc;
//   //         }, {})
//   //       );
//   //     } catch (error) {
//   //       console.error('Error fetching shared form:', error);
//   //     }
//   //   };

//   //   fetchFormStructure();
//   // }, [formId]);

//   ///// for final application form
//   useEffect(() => {
//     console.log("Received formId:", formId); // Log the received formId
//     // rest of the effect...
//   }, [formId]);
//   useEffect(() => {
//     if (!formId) {
//       console.error("Form ID is undefined in PublicFormPreview");
//       setIsError(true);
//       return;
//     }
//     const fetchFormStructure = async () => {
//       try {
//         const response = await fetch(
//           `https://mern3-9rgs.onrender.com/api/forms/general/${formId}`
//         );
//         if (!response.ok) {
//           throw new Error("Failed to fetch form structure");
//         }
//         const data = await response.json();
//         setFormStructure(data);
//         setFormValues(
//           data.fields.reduce((acc, field) => {
//             acc[field.label] = field.type === "multiselect" ? [] : "";
//             return acc;
//           }, {})
//         );
//       } catch (error) {
//         console.error("Error fetching form structure:", error);
//         setIsError(true);
//       }
//     };
//     fetchFormStructure();
//   }, [formId]);

//   const handleChange = (label, value) => {
//     const field = formStructure.fields.find((f) => f.label === label);
//     if (field && (!field.maxLength || value.length <= field.maxLength)) {
//       setFormValues((prevValues) => ({
//         ...prevValues,
//         [label]: value,
//       }));
//       setErrors((prevErrors) => ({
//         ...prevErrors,
//         [label]: field.required && value.length < field.minLength,
//       }));
//     }
//   };

//   const handleCheckboxChange = (label, option) => {
//     const field = formStructure.fields.find((f) => f.label === label);
//     const selectedOptions = formValues[label];
//     let newSelectedOptions = [];

//     if (selectedOptions.includes(option)) {
//       newSelectedOptions = selectedOptions.filter((item) => item !== option);
//     } else if (selectedOptions.length < field.maxSelect) {
//       newSelectedOptions = [...selectedOptions, option];
//     }

//     setFormValues((prevValues) => ({
//       ...prevValues,
//       [label]: newSelectedOptions,
//     }));

//     setErrors((prevErrors) => ({
//       ...prevErrors,
//       [label]: field.required && newSelectedOptions.length === 0,
//     }));
//   };

//   const handleFileChange = (label, file) => {
//     if (file) {
//       if (
//         label === "Resume (PDF Format Only)" &&
//         file.type !== "application/pdf"
//       ) {
//         alert("Please upload a PDF file.");
//       } else if (
//         label === "Upload Startup Logo (In PNG/JPG Format)" &&
//         !["image/png", "image/jpeg"].includes(file.type)
//       ) {
//         alert("Please upload a PNG or JPG file.");
//       } else {
//         const currentDateTime = new Date().toLocaleString(); // Get the current date and time as a string

//         setFiles((prevFiles) => {
//           // Filter out any existing file with the same label
//           const filteredFiles = prevFiles.filter((f) => f.label !== label);
//           // Add the new file with the current date and time
//           return [
//             ...filteredFiles,
//             { label, file, uploadedAt: currentDateTime },
//           ];
//         });
//         setFormValues((prevValues) => ({
//           ...prevValues,
//           [label]: `${file.name} (uploaded at ${currentDateTime})`, // Include the upload time in the form values
//         }));
//         setErrors((prevErrors) => ({
//           ...prevErrors,
//           [label]: false,
//         }));
//       }
//     }
//   };

//   const submitForm = async (formId, responses) => {
//     const formData = new FormData();
//     formData.append("formId", formId);
//     formData.append("responses", JSON.stringify(responses));

//     // Check for pipelineId before adding it
//     if (pipelineId) {
//       formData.append("pipelineId", pipelineId); // Add pipelineId to the request
//     } else {
//       console.error("pipelineId is undefined");
//     }

//     files.forEach((fileWrapper) => {
//       formData.append("files", fileWrapper.file); // Corrected to append files
//     });

//     try {
//       const response = await fetch(
//         "https://mern3-9rgs.onrender.com/api/forms/public-form-submission",
//         {
//           method: "POST",
//           body: formData,
//         }
//       );

//       if (!response.ok) {
//         const errorData = await response.json();
//         console.error("Error submitting form:", errorData.error);
//         if (errorData.error === "Form is already submitted for this email.") {
//           setIsDuplicateSubmission(true);
//         } else {
//           setIsError(true);
//         }
//         throw new Error(errorData.error);
//       } else {
//         console.log("Form submitted successfully");
//       }
//     } catch (error) {
//       console.error("Error submitting form:", error);
//       setIsError(true); // Correct error handling
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     const newErrors = {};
//     let hasError = false;

//     for (const field of formStructure.fields) {
//       if (
//         !formValues[field.label] ||
//         formValues[field.label].length < field.minLength
//       ) {
//         newErrors[field.label] = true;
//         hasError = true;
//       }

//       if (
//         field.type === "multiselect" &&
//         formValues[field.label].length === 0
//       ) {
//         newErrors[field.label] = true;
//         hasError = true;
//       }
//     }

//     if (hasError) {
//       setErrors(newErrors);
//       return;
//     }

//     try {
//       await submitForm(formId, formValues);
//       setIsSubmitted(true);
//     } catch (error) {
//       console.error("Error submitting form:", error);
//     }
//   };

//   const handleClearForm = () => {
//     setFormValues(
//       formStructure.fields.reduce((acc, field) => {
//         acc[field.label] = field.type === "multiselect" ? [] : "";
//         return acc;
//       }, {})
//     );
//     setFiles([]);
//     setErrors({});
//   };

//   if (!formStructure) {
//     return <div>Loading form...</div>;
//   }

//   if (isDuplicateSubmission) {
//     return (
//       <div className="response-page-publicformpreview">
//         <div className="response-message-container-publicformpreview">
//           <h1 className="response-title-publicformpreview">
//             You've already responded
//           </h1>
//           <p className="response-text-publicformpreview">
//             Thanks for submitting your contact info!
//           </p>
//           <p className="response-text-publicformpreview">
//             You can only fill in this form once.
//           </p>
//         </div>
//       </div>
//     );
//   }

//   if (isError) {
//     return (
//       <div className="response-page-publicformpreview">
//         <div className="response-message-container-publicformpreview">
//           <h1 className="response-title-publicformpreview">
//             You've already responded
//           </h1>
//           <p className="response-text-publicformpreview">
//             Thanks for submitting your information!
//           </p>
//           <p className="response-text-publicformpreview">
//             You can only fill in this form once.
//           </p>
//         </div>
//       </div>
//     );
//   }

//   return (
//     <div className="custom-background-publicformpreview">
//       {isSubmitted ? (
//         <div className="thank-you-message-publicformpreview">
//           <div className="icon-container-publicformpreview">
//             <svg
//               width="64"
//               height="64"
//               viewBox="0 0 64 64"
//               xmlns="http://www.w3.org/2000/svg"
//             >
//               <g fill="none" fillRule="evenodd">
//                 <g transform="translate(1 1)" fillRule="nonzero">
//                   <path
//                     d="M48 6H16C11.589 6 8 9.589 8 14v32c0 4.411 3.589 8 8 8h32c4.411 0 8-3.589 8-8V14c0-4.411-3.589-8-8-8z"
//                     fill="#EFF6FF"
//                   />
//                   <path
//                     d="M56 0H8C3.589 0 0 3.589 0 8v48c0 4.411 3.589 8 8 8h48c4.411 0 8-3.589 8-8V8c0-4.411-3.589-8-8-8z"
//                     fill="#EFF6FF"
//                   />
//                   <path
//                     d="M44.293 20.293a1 1 0 00-1.414 0L24 39.172l-6.879-6.879a1 1 0 00-1.414 1.414l8 8a1 1 0 001.414 0l20-20a1 1 0 000-1.414z"
//                     fill="#1E88E5"
//                   />
//                 </g>
//               </g>
//             </svg>
//           </div>
//           <h2>Thank you for your submission!</h2>
//         </div>
//       ) : (
//         <div className="custom-public-form-preview-publicformpreview">
//           <div className="header-publicformpreview">
//             <img
//               src="/navbar/drishtilogo.jpg"
//               alt="Logo"
//               className="logo-publicformpreview"
//             />
//             <div className="header-text-publicformpreview">
//               <h1 className="company-title-publicformpreview">
//                 IITI DRISHTI CPS FOUNDATION
//               </h1>
//               <h2 className="company-subtitle-publicformpreview">IIT INDORE</h2>
//             </div>
//           </div>
//           <hr />
//           {/* <h2 className="custom-form-title-publicformpreview">{formStructure.title}</h2> */}
//           <h2 className="custom-form-title-publicformpreview"> </h2>
//           <form
//             onSubmit={handleSubmit}
//             className="custom-form-publicformpreview"
//           >
//             <div className="custom-form-row-publicformpreview">
//               {formStructure.fields.map((field, index) => (
//                 <div
//                   key={index}
//                   className="custom-form-group-publicformpreview"
//                 >
//                   <label className="custom-form-label-publicformpreview">
//                     {field.label}
//                   </label>
//                   {field.type === "select" ? (
//                     <select
//                       className="custom-form-input-publicformpreview"
//                       value={formValues[field.label]}
//                       onChange={(e) =>
//                         handleChange(field.label, e.target.value)
//                       }
//                       required={field.required}
//                     >
//                       <option value="">Select...</option>
//                       {field.options.map((option, optionIndex) => (
//                         <option key={optionIndex} value={option}>
//                           {option}
//                         </option>
//                       ))}
//                     </select>
//                   ) : field.type === "multiselect" ? (
//                     field.options.map((option, optionIndex) => (
//                       <div
//                         key={optionIndex}
//                         className="checkbox-group-publicformpreview"
//                       >
//                         <input
//                           type="checkbox"
//                           id={`${field.label}-${optionIndex}`}
//                           value={option}
//                           checked={formValues[field.label].includes(option)}
//                           onChange={() =>
//                             handleCheckboxChange(field.label, option)
//                           }
//                           disabled={
//                             !formValues[field.label].includes(option) &&
//                             formValues[field.label].length >= field.maxSelect
//                           }
//                         />
//                         <label
//                           htmlFor={`${field.label}-${optionIndex}`}
//                           className="option-label-publicformpreview"
//                           style={{ fontSize: "12px" }}
//                         >
//                           {option}
//                         </label>
//                       </div>
//                     ))
//                   ) : field.type === "radio" ? (
//                     field.options.map((option, optionIndex) => (
//                       <div
//                         key={optionIndex}
//                         className="radio-group-publicformpreview"
//                       >
//                         <input
//                           type="radio"
//                           id={`${field.label}-${optionIndex}`}
//                           name={field.label}
//                           value={option}
//                           checked={formValues[field.label] === option}
//                           onChange={(e) =>
//                             handleChange(field.label, e.target.value)
//                           }
//                           required={field.required}
//                         />
//                         <label
//                           htmlFor={`${field.label}-${optionIndex}`}
//                           className="option-label-publicformpreview-radio"
//                           style={{ fontSize: "14px" }}
//                         >
//                           {option}
//                         </label>
//                       </div>
//                     ))
//                   ) : field.type === "file" ? (
//                     <input
//                       className="custom-form-input-publicformpreview"
//                       type="file"
//                       onChange={(e) =>
//                         handleFileChange(field.label, e.target.files[0])
//                       }
//                       required={field.required}
//                     />
//                   ) : field.type === "phone" ? (
//                     // Change applied to set the default country to India (+91)
//                     <PhoneInput
//                       country={"in"} // <-- Default country changed to 'in' (India)
//                       value={formValues[field.label]}
//                       onChange={(value) => handleChange(field.label, value)}
//                       inputClass="custom-form-input-publicformpreview" // Match the class name
//                       required={field.required}
//                     />
//                   ) : (
//                     <input
//                       className={`custom-form-input-publicformpreview ${
//                         errors[field.label] ? "error-publicformpreview" : ""
//                       }`}
//                       type={field.type}
//                       placeholder={field.placeholder}
//                       value={formValues[field.label]}
//                       onChange={(e) =>
//                         handleChange(field.label, e.target.value)
//                       }
//                       required={field.required}
//                       maxLength={field.maxLength || undefined}
//                     />
//                   )}
//                   {errors[field.label] && (
//                     <div className="error-message-publicformpreview">
//                       <FaInfoCircle className="error-icon-publicformpreview" />{" "}
//                       This field is required
//                     </div>
//                   )}
//                   {(field.maxLength || field.minLength) &&
//                     field.label !== "Contact Number" &&
//                     field.label !== "Startup team size" && (
//                       <div className="character-limit-publicformpreview">
//                         {field.maxLength &&
//                           `${
//                             field.maxLength -
//                             (formValues[field.label]?.length || 0)
//                           } characters remaining`}
//                         {field.minLength &&
//                           (formValues[field.label]?.length || 0) <
//                             field.minLength &&
//                           ` (Min: ${field.minLength} characters)`}
//                       </div>
//                     )}
//                 </div>
//               ))}
//             </div>
//             <div className="form-buttons-publicformpreview">
//               <button
//                 type="submit"
//                 className="custom-form-submit-button-publicformpreviewfinalsave"
//                 style={{
//                   width: "22%",
//                   border: "none",
//                   borderRadius: "4px",
//                   fontSize: "13px",
//                   padding: "10px",
//                 }}
//               >
//                 Submit
//               </button>
//               <button
//                 type="button"
//                 className="custom-form-clear-button-publicformpreview"
//                 onClick={() => handleClearForm()}
//               >
//                 Clear Form
//               </button>
//             </div>
//           </form>
//         </div>
//       )}
//     </div>
//   );
// };

// export default PublicFormPreview;

////////////////////////////////////////  before pipeline separate application count come    04 09 2024///////////

// //////////after 1 / 9 / 2023  in Form.jsx page shear is not working but in FinalApplicationForm page DnD form good show

// import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
// import PhoneInput from 'react-phone-input-2'; // Import the PhoneInput component
// import 'react-phone-input-2/lib/style.css'; // Import the CSS for react-phone-input-2
// import './PublicFormPreview.css';
// import { FaInfoCircle } from 'react-icons/fa';

// const PublicFormPreview = ({ formId }) => {
//   // const { formId } = useParams();
//   const [formStructure, setFormStructure] = useState(null);
//   const [formValues, setFormValues] = useState({});
//   const [errors, setErrors] = useState({});
//   const [files, setFiles] = useState([]);
//   const [isSubmitted, setIsSubmitted] = useState(false);
//   const [isDuplicateSubmission, setIsDuplicateSubmission] = useState(false);
//   const [isError, setIsError] = useState(false);

//   // useEffect(() => {
//   //   const fetchFormStructure = async () => {
//   //     try {
//   //       const response = await fetch(`http://localhost:5000/api/forms/general/${formId}`);
//   //       const data = await response.json();
//   //       setFormStructure(data);
//   //       setFormValues(
//   //         data.fields.reduce((acc, field) => {
//   //           acc[field.label] = field.type === 'multiselect' ? [] : '';
//   //           return acc;
//   //         }, {})
//   //       );
//   //     } catch (error) {
//   //       console.error('Error fetching shared form:', error);
//   //     }
//   //   };

//   //   fetchFormStructure();
//   // }, [formId]);

//   ///// for final application form
//     useEffect(() => {
//     console.log('Received formId:', formId); // Log the received formId
//     // rest of the effect...
//   }, [formId]);
//   useEffect(() => {
//     if (!formId) {
//       console.error('Form ID is undefined in PublicFormPreview');
//       setIsError(true);
//       return;
//     }
//     const fetchFormStructure = async () => {
//       try {
//         const response = await fetch(`http://localhost:5000/api/forms/general/${formId}`);
//         if (!response.ok) {
//           throw new Error('Failed to fetch form structure');
//         }
//         const data = await response.json();
//         setFormStructure(data);
//         setFormValues(
//           data.fields.reduce((acc, field) => {
//             acc[field.label] = field.type === 'multiselect' ? [] : '';
//             return acc;
//           }, {})
//         );
//       } catch (error) {
//         console.error('Error fetching form structure:', error);
//         setIsError(true);
//       }
//     };
//     fetchFormStructure();
//   }, [formId]);

//   const handleChange = (label, value) => {
//     const field = formStructure.fields.find(f => f.label === label);
//     if (field && (!field.maxLength || value.length <= field.maxLength)) {
//       setFormValues((prevValues) => ({
//         ...prevValues,
//         [label]: value,
//       }));
//       setErrors((prevErrors) => ({
//         ...prevErrors,
//         [label]: field.required && value.length < field.minLength,
//       }));
//     }
//   };

//   const handleCheckboxChange = (label, option) => {
//     const field = formStructure.fields.find(f => f.label === label);
//     const selectedOptions = formValues[label];
//     let newSelectedOptions = [];

//     if (selectedOptions.includes(option)) {
//       newSelectedOptions = selectedOptions.filter((item) => item !== option);
//     } else if (selectedOptions.length < field.maxSelect) {
//       newSelectedOptions = [...selectedOptions, option];
//     }

//     setFormValues((prevValues) => ({
//       ...prevValues,
//       [label]: newSelectedOptions,
//     }));

//     setErrors((prevErrors) => ({
//       ...prevErrors,
//       [label]: field.required && newSelectedOptions.length === 0,
//     }));
//   };

//   const handleFileChange = (label, file) => {
//     if (file) {
//       if (label === 'Resume (PDF Format Only)' && file.type !== 'application/pdf') {
//         alert('Please upload a PDF file.');
//       } else if (label === 'Upload Startup Logo (In PNG/JPG Format)' && !['image/png', 'image/jpeg'].includes(file.type)) {
//         alert('Please upload a PNG or JPG file.');
//       } else {
//         const currentDateTime = new Date().toLocaleString(); // Get the current date and time as a string

//         setFiles((prevFiles) => {
//           // Filter out any existing file with the same label
//           const filteredFiles = prevFiles.filter(f => f.label !== label);
//           // Add the new file with the current date and time
//           return [
//             ...filteredFiles,
//             { label, file, uploadedAt: currentDateTime },
//           ];
//         });
//         setFormValues((prevValues) => ({
//           ...prevValues,
//           [label]: `${file.name} (uploaded at ${currentDateTime})`, // Include the upload time in the form values
//         }));
//         setErrors((prevErrors) => ({
//           ...prevErrors,
//           [label]: false,
//         }));
//       }
//     }
//   };

//   const submitForm = async (formId, responses) => {
//     const formData = new FormData();
//     formData.append('formId', formId);
//     formData.append('responses', JSON.stringify(responses));

//     files.forEach((fileWrapper) => {
//       formData.append('files', fileWrapper.file); // Corrected to append files
//     });

//     try {
//       const response = await fetch('http://localhost:5000/api/forms/public-form-submission', {
//         method: 'POST',
//         body: formData,
//       });

//       if (!response.ok) {
//         const errorData = await response.json();
//         console.error('Error submitting form:', errorData.error);
//         if (errorData.error === 'Form is already submitted for this email.') {
//           setIsDuplicateSubmission(true);
//         } else {
//           setIsError(true);
//         }
//         throw new Error(errorData.error);
//       } else {
//         console.log('Form submitted successfully');
//       }
//     } catch (error) {
//       console.error('Error submitting form:', error);
//       setIsError(true); // Correct error handling
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     const newErrors = {};
//     let hasError = false;

//     for (const field of formStructure.fields) {
//       if (!formValues[field.label] || formValues[field.label].length < field.minLength) {
//         newErrors[field.label] = true;
//         hasError = true;
//       }

//       if (field.type === 'multiselect' && formValues[field.label].length === 0) {
//         newErrors[field.label] = true;
//         hasError = true;
//       }
//     }

//     if (hasError) {
//       setErrors(newErrors);
//       return;
//     }

//     try {
//       await submitForm(formId, formValues);
//       setIsSubmitted(true);
//     } catch (error) {
//       console.error('Error submitting form:', error);
//     }
//   };

//   const handleClearForm = () => {
//     setFormValues(
//       formStructure.fields.reduce((acc, field) => {
//         acc[field.label] = field.type === 'multiselect' ? [] : '';
//         return acc;
//       }, {})
//     );
//     setFiles([]);
//     setErrors({});
//   };

//   if (!formStructure) {
//     return <div>Loading form...</div>;
//   }

//   if (isDuplicateSubmission) {
//     return (
//       <div className="response-page-publicformpreview">
//         <div className="response-message-container-publicformpreview">
//           <h1 className="response-title-publicformpreview">You've already responded</h1>
//           <p className="response-text-publicformpreview">Thanks for submitting your contact info!</p>
//           <p className="response-text-publicformpreview">You can only fill in this form once.</p>
//         </div>
//       </div>
//     );
//   }

//   if (isError) {
//     return (
//       <div className="response-page-publicformpreview">
//         <div className="response-message-container-publicformpreview">
//           <h1 className="response-title-publicformpreview">You've already responded</h1>
//           <p className="response-text-publicformpreview">Thanks for submitting your information!</p>
//           <p className="response-text-publicformpreview">You can only fill in this form once.</p>
//         </div>
//       </div>
//     );
//   }

//   return (
//     <div className="custom-background-publicformpreview">
//       {isSubmitted ? (
//         <div className="thank-you-message-publicformpreview">
//           <div className="icon-container-publicformpreview">
//             <svg
//               width="64"
//               height="64"
//               viewBox="0 0 64 64"
//               xmlns="http://www.w3.org/2000/svg"
//             >
//               <g fill="none" fillRule="evenodd">
//                 <g transform="translate(1 1)" fillRule="nonzero">
//                   <path
//                     d="M48 6H16C11.589 6 8 9.589 8 14v32c0 4.411 3.589 8 8 8h32c4.411 0 8-3.589 8-8V14c0-4.411-3.589-8-8-8z"
//                     fill="#EFF6FF"
//                   />
//                   <path
//                     d="M56 0H8C3.589 0 0 3.589 0 8v48c0 4.411 3.589 8 8 8h48c4.411 0 8-3.589 8-8V8c0-4.411-3.589-8-8-8z"
//                     fill="#EFF6FF"
//                   />
//                   <path
//                     d="M44.293 20.293a1 1 0 00-1.414 0L24 39.172l-6.879-6.879a1 1 0 00-1.414 1.414l8 8a1 1 0 001.414 0l20-20a1 1 0 000-1.414z"
//                     fill="#1E88E5"
//                   />
//                 </g>
//               </g>
//             </svg>
//           </div>
//           <h2>Thank you for your submission!</h2>
//         </div>
//       ) : (
//         <div className="custom-public-form-preview-publicformpreview">
//           <div className="header-publicformpreview">
//             <img src="/navbar/drishtilogo.jpg" alt="Logo" className="logo-publicformpreview" />
//             <div className="header-text-publicformpreview">
//               <h1 className="company-title-publicformpreview">IITI DRISHTI CPS FOUNDATION</h1>
//               <h2 className="company-subtitle-publicformpreview">IIT INDORE</h2>
//             </div>
//           </div>
//           <hr />
//           {/* <h2 className="custom-form-title-publicformpreview">{formStructure.title}</h2> */}
//           <h2 className="custom-form-title-publicformpreview"> </h2>
//           <form onSubmit={handleSubmit} className="custom-form-publicformpreview">
//             <div className="custom-form-row-publicformpreview">
//               {formStructure.fields.map((field, index) => (
//                 <div key={index} className="custom-form-group-publicformpreview">
//                   <label className="custom-form-label-publicformpreview">{field.label}</label>
//                   {field.type === 'select' ? (
//                     <select
//                       className="custom-form-input-publicformpreview"
//                       value={formValues[field.label]}
//                       onChange={(e) => handleChange(field.label, e.target.value)}
//                       required={field.required}
//                     >
//                       <option value="">Select...</option>
//                       {field.options.map((option, optionIndex) => (
//                         <option key={optionIndex} value={option}>
//                           {option}
//                         </option>
//                       ))}
//                     </select>
//                   ) : field.type === 'multiselect' ? (
//                     field.options.map((option, optionIndex) => (
//                       <div key={optionIndex} className="checkbox-group-publicformpreview">
//                         <input
//                           type="checkbox"
//                           id={`${field.label}-${optionIndex}`}
//                           value={option}
//                           checked={formValues[field.label].includes(option)}
//                           onChange={() => handleCheckboxChange(field.label, option)}
//                           disabled={!formValues[field.label].includes(option) && formValues[field.label].length >= field.maxSelect}
//                         />
//                         <label htmlFor={`${field.label}-${optionIndex}`} className="option-label-publicformpreview" style={{ fontSize: '12px' }}>{option}</label>
//                       </div>
//                     ))
//                   ) : field.type === 'radio' ? (
//                     field.options.map((option, optionIndex) => (
//                       <div key={optionIndex} className="radio-group-publicformpreview">
//                         <input
//                           type="radio"
//                           id={`${field.label}-${optionIndex}`}
//                           name={field.label}
//                           value={option}
//                           checked={formValues[field.label] === option}
//                           onChange={(e) => handleChange(field.label, e.target.value)}
//                           required={field.required}
//                         />
//                         <label htmlFor={`${field.label}-${optionIndex}`} className="option-label-publicformpreview-radio" style={{ fontSize: '14px' }}>{option}</label>
//                       </div>
//                     ))
//                   ) : field.type === 'file' ? (
//                     <input
//                       className="custom-form-input-publicformpreview"
//                       type="file"
//                       onChange={(e) => handleFileChange(field.label, e.target.files[0])}
//                       required={field.required}
//                     />
//                   ) : field.type === 'phone' ? (
//                     // Change applied to set the default country to India (+91)
//                     <PhoneInput
//                       country={'in'} // <-- Default country changed to 'in' (India)
//                       value={formValues[field.label]}
//                       onChange={(value) => handleChange(field.label, value)}
//                       inputClass="custom-form-input-publicformpreview" // Match the class name
//                       required={field.required}
//                     />
//                   ) : (
//                     <input
//                       className={`custom-form-input-publicformpreview ${errors[field.label] ? 'error-publicformpreview' : ''}`}
//                       type={field.type}
//                       placeholder={field.placeholder}
//                       value={formValues[field.label]}
//                       onChange={(e) => handleChange(field.label, e.target.value)}
//                       required={field.required}
//                       maxLength={field.maxLength || undefined}
//                     />
//                   )}
//                   {errors[field.label] && (
//                     <div className="error-message-publicformpreview">
//                       <FaInfoCircle className="error-icon-publicformpreview" /> This field is required
//                     </div>
//                   )}
//                   {(field.maxLength || field.minLength) && field.label !== 'Contact Number' && field.label !== 'Startup team size' && (
//                     <div className="character-limit-publicformpreview">
//                       {field.maxLength && `${field.maxLength - (formValues[field.label]?.length || 0)} characters remaining`}
//                       {field.minLength && (formValues[field.label]?.length || 0) < field.minLength && ` (Min: ${field.minLength} characters)`}
//                     </div>
//                   )}
//                 </div>
//               ))}
//             </div>
//             <div className="form-buttons-publicformpreview">
//               <button type="submit" className="custom-form-submit-button-publicformpreviewfinalsave" style={{ width: '22%', border: 'none', borderRadius: '4px', fontSize: '13px', padding: '10px' }}>Submit</button>
//               <button type="button" className="custom-form-clear-button-publicformpreview" onClick={() => handleClearForm()}>Clear Form</button>
//             </div>
//           </form>
//         </div>
//       )}
//     </div>
//   );
// };

// export default PublicFormPreview;

//////////before final form 1 / 9 / 2023  in Form.jsx  page shear is working but in FinalApplicationForm page DnD form not show

// import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
// import PhoneInput from 'react-phone-input-2'; // Import the PhoneInput component
// import 'react-phone-input-2/lib/style.css'; // Import the CSS for react-phone-input-2
// import './PublicFormPreview.css';
// import { FaInfoCircle } from 'react-icons/fa';

// const PublicFormPreview = () => {
//   const { formId } = useParams();
//   const [formStructure, setFormStructure] = useState(null);
//   const [formValues, setFormValues] = useState({});
//   const [errors, setErrors] = useState({});
//   const [files, setFiles] = useState([]);
//   const [isSubmitted, setIsSubmitted] = useState(false);
//   const [isDuplicateSubmission, setIsDuplicateSubmission] = useState(false);
//   const [isError, setIsError] = useState(false);

//   useEffect(() => {
//     const fetchFormStructure = async () => {
//       try {
//         const response = await fetch(`http://localhost:5000/api/forms/general/${formId}`);
//         const data = await response.json();
//         setFormStructure(data);
//         setFormValues(
//           data.fields.reduce((acc, field) => {
//             acc[field.label] = field.type === 'multiselect' ? [] : '';
//             return acc;
//           }, {})
//         );
//       } catch (error) {
//         console.error('Error fetching shared form:', error);
//       }
//     };

//     fetchFormStructure();
//   }, [formId]);

//   const handleChange = (label, value) => {
//     const field = formStructure.fields.find(f => f.label === label);
//     if (field && (!field.maxLength || value.length <= field.maxLength)) {
//       setFormValues((prevValues) => ({
//         ...prevValues,
//         [label]: value,
//       }));
//       setErrors((prevErrors) => ({
//         ...prevErrors,
//         [label]: field.required && value.length < field.minLength,
//       }));
//     }
//   };

//   const handleCheckboxChange = (label, option) => {
//     const field = formStructure.fields.find(f => f.label === label);
//     const selectedOptions = formValues[label];
//     let newSelectedOptions = [];

//     if (selectedOptions.includes(option)) {
//       newSelectedOptions = selectedOptions.filter((item) => item !== option);
//     } else if (selectedOptions.length < field.maxSelect) {
//       newSelectedOptions = [...selectedOptions, option];
//     }

//     setFormValues((prevValues) => ({
//       ...prevValues,
//       [label]: newSelectedOptions,
//     }));

//     setErrors((prevErrors) => ({
//       ...prevErrors,
//       [label]: field.required && newSelectedOptions.length === 0,
//     }));
//   };

//   const handleFileChange = (label, file) => {
//     if (file) {
//       if (label === 'Resume (PDF Format Only)' && file.type !== 'application/pdf') {
//         alert('Please upload a PDF file.');
//       } else if (label === 'Upload Startup Logo (In PNG/JPG Format)' && !['image/png', 'image/jpeg'].includes(file.type)) {
//         alert('Please upload a PNG or JPG file.');
//       } else {
//         const currentDateTime = new Date().toLocaleString(); // Get the current date and time as a string

//         setFiles((prevFiles) => {
//           // Filter out any existing file with the same label
//           const filteredFiles = prevFiles.filter(f => f.label !== label);
//           // Add the new file with the current date and time
//           return [
//             ...filteredFiles,
//             { label, file, uploadedAt: currentDateTime },
//           ];
//         });
//         setFormValues((prevValues) => ({
//           ...prevValues,
//           [label]: `${file.name} (uploaded at ${currentDateTime})`, // Include the upload time in the form values
//         }));
//         setErrors((prevErrors) => ({
//           ...prevErrors,
//           [label]: false,
//         }));
//       }
//     }
//   };

//   const submitForm = async (formId, responses) => {
//     const formData = new FormData();
//     formData.append('formId', formId);
//     formData.append('responses', JSON.stringify(responses));

//     files.forEach((fileWrapper) => {
//       formData.append('files', fileWrapper.file); // Corrected to append files
//     });

//     try {
//       const response = await fetch('http://localhost:5000/api/forms/public-form-submission', {
//         method: 'POST',
//         body: formData,
//       });

//       if (!response.ok) {
//         const errorData = await response.json();
//         console.error('Error submitting form:', errorData.error);
//         if (errorData.error === 'Form is already submitted for this email.') {
//           setIsDuplicateSubmission(true);
//         } else {
//           setIsError(true);
//         }
//         throw new Error(errorData.error);
//       } else {
//         console.log('Form submitted successfully');
//       }
//     } catch (error) {
//       console.error('Error submitting form:', error);
//       setIsError(true); // Correct error handling
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     const newErrors = {};
//     let hasError = false;

//     for (const field of formStructure.fields) {
//       if (!formValues[field.label] || formValues[field.label].length < field.minLength) {
//         newErrors[field.label] = true;
//         hasError = true;
//       }

//       if (field.type === 'multiselect' && formValues[field.label].length === 0) {
//         newErrors[field.label] = true;
//         hasError = true;
//       }
//     }

//     if (hasError) {
//       setErrors(newErrors);
//       return;
//     }

//     try {
//       await submitForm(formId, formValues);
//       setIsSubmitted(true);
//     } catch (error) {
//       console.error('Error submitting form:', error);
//     }
//   };

//   const handleClearForm = () => {
//     setFormValues(
//       formStructure.fields.reduce((acc, field) => {
//         acc[field.label] = field.type === 'multiselect' ? [] : '';
//         return acc;
//       }, {})
//     );
//     setFiles([]);
//     setErrors({});
//   };

//   if (!formStructure) {
//     return <div>Loading form...</div>;
//   }

//   if (isDuplicateSubmission) {
//     return (
//       <div className="response-page-publicformpreview">
//         <div className="response-message-container-publicformpreview">
//           <h1 className="response-title-publicformpreview">You've already responded</h1>
//           <p className="response-text-publicformpreview">Thanks for submitting your contact info!</p>
//           <p className="response-text-publicformpreview">You can only fill in this form once.</p>
//         </div>
//       </div>
//     );
//   }

//   if (isError) {
//     return (
//       <div className="response-page-publicformpreview">
//         <div className="response-message-container-publicformpreview">
//           <h1 className="response-title-publicformpreview">You've already responded</h1>
//           <p className="response-text-publicformpreview">Thanks for submitting your information!</p>
//           <p className="response-text-publicformpreview">You can only fill in this form once.</p>
//         </div>
//       </div>
//     );
//   }

//   return (
//     <div className="custom-background-publicformpreview">
//       {isSubmitted ? (
//         <div className="thank-you-message-publicformpreview">
//           <div className="icon-container-publicformpreview">
//             <svg
//               width="64"
//               height="64"
//               viewBox="0 0 64 64"
//               xmlns="http://www.w3.org/2000/svg"
//             >
//               <g fill="none" fillRule="evenodd">
//                 <g transform="translate(1 1)" fillRule="nonzero">
//                   <path
//                     d="M48 6H16C11.589 6 8 9.589 8 14v32c0 4.411 3.589 8 8 8h32c4.411 0 8-3.589 8-8V14c0-4.411-3.589-8-8-8z"
//                     fill="#EFF6FF"
//                   />
//                   <path
//                     d="M56 0H8C3.589 0 0 3.589 0 8v48c0 4.411 3.589 8 8 8h48c4.411 0 8-3.589 8-8V8c0-4.411-3.589-8-8-8z"
//                     fill="#EFF6FF"
//                   />
//                   <path
//                     d="M44.293 20.293a1 1 0 00-1.414 0L24 39.172l-6.879-6.879a1 1 0 00-1.414 1.414l8 8a1 1 0 001.414 0l20-20a1 1 0 000-1.414z"
//                     fill="#1E88E5"
//                   />
//                 </g>
//               </g>
//             </svg>
//           </div>
//           <h2>Thank you for your submission!</h2>
//         </div>
//       ) : (
//         <div className="custom-public-form-preview-publicformpreview">
//           <div className="header-publicformpreview">
//             <img src="/navbar/drishtilogo.jpg" alt="Logo" className="logo-publicformpreview" />
//             <div className="header-text-publicformpreview">
//               <h1 className="company-title-publicformpreview">IITI DRISHTI CPS FOUNDATION</h1>
//               <h2 className="company-subtitle-publicformpreview">IIT INDORE</h2>
//             </div>
//           </div>
//           <hr />
//           <h2 className="custom-form-title-publicformpreview">{formStructure.title}</h2>
//           <form onSubmit={handleSubmit} className="custom-form-publicformpreview">
//             <div className="custom-form-row-publicformpreview">
//               {formStructure.fields.map((field, index) => (
//                 <div key={index} className="custom-form-group-publicformpreview">
//                   <label className="custom-form-label-publicformpreview">{field.label}</label>
//                   {field.type === 'select' ? (
//                     <select
//                       className="custom-form-input-publicformpreview"
//                       value={formValues[field.label]}
//                       onChange={(e) => handleChange(field.label, e.target.value)}
//                       required={field.required}
//                     >
//                       <option value="">Select...</option>
//                       {field.options.map((option, optionIndex) => (
//                         <option key={optionIndex} value={option}>
//                           {option}
//                         </option>
//                       ))}
//                     </select>
//                   ) : field.type === 'multiselect' ? (
//                     field.options.map((option, optionIndex) => (
//                       <div key={optionIndex} className="checkbox-group-publicformpreview">
//                         <input
//                           type="checkbox"
//                           id={`${field.label}-${optionIndex}`}
//                           value={option}
//                           checked={formValues[field.label].includes(option)}
//                           onChange={() => handleCheckboxChange(field.label, option)}
//                           disabled={!formValues[field.label].includes(option) && formValues[field.label].length >= field.maxSelect}
//                         />
//                         <label htmlFor={`${field.label}-${optionIndex}`} className="option-label-publicformpreview" style={{ fontSize: '12px' }}>{option}</label>
//                       </div>
//                     ))
//                   ) : field.type === 'radio' ? (
//                     field.options.map((option, optionIndex) => (
//                       <div key={optionIndex} className="radio-group-publicformpreview">
//                         <input
//                           type="radio"
//                           id={`${field.label}-${optionIndex}`}
//                           name={field.label}
//                           value={option}
//                           checked={formValues[field.label] === option}
//                           onChange={(e) => handleChange(field.label, e.target.value)}
//                           required={field.required}
//                         />
//                         <label htmlFor={`${field.label}-${optionIndex}`} className="option-label-publicformpreview-radio" style={{ fontSize: '14px' }}>{option}</label>
//                       </div>
//                     ))
//                   ) : field.type === 'file' ? (
//                     <input
//                       className="custom-form-input-publicformpreview"
//                       type="file"
//                       onChange={(e) => handleFileChange(field.label, e.target.files[0])}
//                       required={field.required}
//                     />
//                   ) : field.type === 'phone' ? (
//                     // Change applied to set the default country to India (+91)
//                     <PhoneInput
//                       country={'in'} // <-- Default country changed to 'in' (India)
//                       value={formValues[field.label]}
//                       onChange={(value) => handleChange(field.label, value)}
//                       inputClass="custom-form-input-publicformpreview" // Match the class name
//                       required={field.required}
//                     />
//                   ) : (
//                     <input
//                       className={`custom-form-input-publicformpreview ${errors[field.label] ? 'error-publicformpreview' : ''}`}
//                       type={field.type}
//                       placeholder={field.placeholder}
//                       value={formValues[field.label]}
//                       onChange={(e) => handleChange(field.label, e.target.value)}
//                       required={field.required}
//                       maxLength={field.maxLength || undefined}
//                     />
//                   )}
//                   {errors[field.label] && (
//                     <div className="error-message-publicformpreview">
//                       <FaInfoCircle className="error-icon-publicformpreview" /> This field is required
//                     </div>
//                   )}
//                   {(field.maxLength || field.minLength) && field.label !== 'Contact Number' && field.label !== 'Startup team size' && (
//                     <div className="character-limit-publicformpreview">
//                       {field.maxLength && `${field.maxLength - (formValues[field.label]?.length || 0)} characters remaining`}
//                       {field.minLength && (formValues[field.label]?.length || 0) < field.minLength && ` (Min: ${field.minLength} characters)`}
//                     </div>
//                   )}
//                 </div>
//               ))}
//             </div>
//             <div className="form-buttons-publicformpreview">
//               <button type="submit" className="custom-form-submit-button-publicformpreviewfinalsave" style={{ width: '22%', border: 'none', borderRadius: '4px', fontSize: '13px', padding: '10px' }}>Submit</button>
//               <button type="button" className="custom-form-clear-button-publicformpreview" onClick={() => handleClearForm()}>Clear Form</button>
//             </div>
//           </form>
//         </div>
//       )}
//     </div>
//   );
// };

// export default PublicFormPreview;
