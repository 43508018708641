import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import PublicFormPreview from "../Form/PublicFormPreview.jsx";
import "./FinalApplicationForm.css";

const FinalApplicationForm = () => {
  const { id } = useParams();
  const [applicationData, setApplicationData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchApplicationData = async () => {
      try {
        const pipelineResponse = await axios.get(
          `https://mern3-9rgs.onrender.com/api/pipelines/${id}`
        );
        const pipeline = pipelineResponse.data;

        // console.log("Pipeline Data:", pipeline);

        const formId = pipeline.forms; // Assuming `forms` is directly a string
        const pipelineId = pipeline._id; // Added to get pipelineId
        // Adjusting how we extract the poster URL
        let posterUrl = "";
        if (pipeline.poster) {
          if (Array.isArray(pipeline.poster) && pipeline.poster.length > 0) {
            // Check if the URL is already an absolute URL
            if (pipeline.poster[0].url.startsWith("http")) {
              posterUrl = pipeline.poster[0].url;
            } else {
              posterUrl = `https://mern3-9rgs.onrender.com/${pipeline.poster[0].url}`;
            }
          } else if (pipeline.poster.url) {
            // Check if the URL is already an absolute URL
            if (pipeline.poster.url.startsWith("http")) {
              posterUrl = pipeline.poster.url;
            } else {
              posterUrl = `https://mern3-9rgs.onrender.com/${pipeline.poster.url}`;
            }
          }
        }
        // Log the poster URL for debugging
        // console.log("Poster URL:", posterUrl);
        setApplicationData({
          title: pipeline.applicationTitle,
          poster: posterUrl, // Set the poster URL
          description: pipeline.description,
          documents: pipeline.supportingDocuments,
          formId: formId, // Ensure the correct formId is set
          pipelineId: pipelineId, // Ensure the correct pipelineId is set
        });

        setLoading(false);
      } catch (error) {
        console.error("Error fetching application data:", error);
        setError(error);
        setLoading(false);
      }
    };

    fetchApplicationData();
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading data: {error.message}</div>;
  }

  // Log formId to verify it's correctly set
  // console.log(
  //   "Form ID being passed to PublicFormPreview:",
  //   applicationData.formId
  // );
  // console.log(
  //   "Pipeline ID being passed to PublicFormPreview:",
  //   applicationData.pipelineId
  // );
  /*** START CHANGE FOR text styling --- ***/
  // Function to safely render HTML in JSX for the description
  const createMarkup = (html) => {
    return { __html: html };
  };
  /*** END CHANGE FOR text styling --- ***/

  return (
    <div className="application-form-containerfinalapplication">
      <h1 className="application-titlefinalapplication">
        {applicationData.title}
      </h1>
      {applicationData.poster && (
        <img
          src={applicationData.poster}
          alt="Application Poster"
          className="application-posterfinalapplication"
        />
      )}
      {/* <p className="application-descriptionfinalapplication">{applicationData.description}</p> */}

      {/* START CHANGE: Render description with text styling */}
      <div
        className="application-descriptionfinalapplication"
        dangerouslySetInnerHTML={createMarkup(applicationData.description)} // Render description with HTML tags
      />
      {/* END CHANGE FOR text styling */}

      {/* START CHANGE Supporting Documents --- */}
      {applicationData.documents && applicationData.documents.length > 0 && (
        <div className="supporting-documentsfinalapplication">
          <h2>Supporting Documents</h2>
          {/* <ul>
            {applicationData.documents.map((doc, index) => (
              <li key={index}>
                <a
                  href={`http://localhost:5000/${doc.url}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {doc.name}
                </a>
              </li>
            ))}
          </ul> */}
          <ul>
            {applicationData.documents.map((doc, index) => {
              // Check if the document URL is already an absolute URL
              const documentUrl = doc.url.startsWith("https")
                ? doc.url
                : `https://mern3-9rgs.onrender.com/${doc.url}`;
              // : `${doc.url}`;
              return (
                <li key={index}>
                  <span className="document-number-documentsfinalapplication">
                    {index + 1}.
                  </span>
                  <a
                    href={documentUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {doc.name}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      )}
      {/* END CHANGE FOR Supporting Documents--- */}

      <div>
        {/* Only render PublicFormPreview if formId is available */}
        {applicationData.formId ? (
          <PublicFormPreview
            formId={applicationData.formId}
            pipelineId={applicationData.pipelineId}
          />
        ) : (
          <p>Loading form...</p>
        )}
      </div>
    </div>
  );
};

export default FinalApplicationForm;

///17 09

// import React, { useState, useEffect } from "react";
// import { useParams } from "react-router-dom";
// import axios from "axios";
// import PublicFormPreview from "../Form/PublicFormPreview.jsx";
// import "./FinalApplicationForm.css";

// const FinalApplicationForm = () => {
//   const { id } = useParams();
//   const [applicationData, setApplicationData] = useState({});
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchApplicationData = async () => {
//       try {
//         const pipelineResponse = await axios.get(
//           `https://mern3-9rgs.onrender.com/api/pipelines/${id}`
//         );
//         const pipeline = pipelineResponse.data;

//         console.log("Pipeline Data:", pipeline);

//         const formId = pipeline.forms; // Assuming `forms` is directly a string
//         const pipelineId = pipeline._id; // Added to get pipelineId

//         setApplicationData({
//           title: pipeline.applicationTitle,
//           poster:
//             pipeline.poster.length > 0
//               ? `https://mern3-9rgs.onrender.com/${pipeline.poster[0].url}`
//               : "",
//           description: pipeline.description,
//           documents: pipeline.supportingDocuments,
//           formId: formId, // Ensure the correct formId is set
//           pipelineId: pipelineId, // Ensure the correct pipelineId is set
//         });

//         setLoading(false);
//       } catch (error) {
//         console.error("Error fetching application data:", error);
//         setError(error);
//         setLoading(false);
//       }
//     };

//     fetchApplicationData();
//   }, [id]);

//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   if (error) {
//     return <div>Error loading data: {error.message}</div>;
//   }

//   // Log formId to verify it's correctly set
//   console.log(
//     "Form ID being passed to PublicFormPreview:",
//     applicationData.formId
//   );
//   console.log(
//     "Pipeline ID being passed to PublicFormPreview:",
//     applicationData.pipelineId
//   );
//   /*** START CHANGE FOR text styling --- ***/
//   // Function to safely render HTML in JSX for the description
//   const createMarkup = (html) => {
//     return { __html: html };
//   };
//   /*** END CHANGE FOR text styling --- ***/

//   return (
//     <div className="application-form-containerfinalapplication">
//       <h1 className="application-titlefinalapplication">
//         {applicationData.title}
//       </h1>
//       {applicationData.poster && (
//         <img
//           src={applicationData.poster}
//           alt="Application Poster"
//           className="application-posterfinalapplication"
//         />
//       )}
//       {/* <p className="application-descriptionfinalapplication">
//         {applicationData.description}
//       </p> */}
//       {/* START CHANGE: Render description with text styling */}
//       <div
//         className="application-descriptionfinalapplication"
//         dangerouslySetInnerHTML={createMarkup(applicationData.description)} // Render description with HTML tags
//       />
//       {/* END CHANGE FOR text styling */}

//       {/* <div className="supporting-documentsfinalapplication">
//         <h3>Supporting Documents</h3>
//         <ul>
//           {applicationData.documents.map((doc, index) => (
//             <li key={index}>
//               <a href={`http://localhost:5000/${doc.url}`} target="_blank" rel="noopener noreferrer">
//                 {doc.name}
//               </a>
//             </li>
//           ))}
//         </ul>
//       </div> */}

//       {/* START CHANGE Supporting Documents --- */}
//       {applicationData.documents && applicationData.documents.length > 0 && (
//         <div className="supporting-documentsfinalapplication">
//           <h2>Supporting Documents</h2>
//           <ul>
//             {applicationData.documents.map((doc, index) => (
//               <li key={index}>
//                 <a
//                   href={`https://mern3-9rgs.onrender.com/${doc.url}`}
//                   target="_blank"
//                   rel="noopener noreferrer"
//                 >
//                   {doc.name}
//                 </a>
//               </li>
//             ))}
//           </ul>
//         </div>
//       )}
//       {/* END CHANGE FOR Supporting Documents--- */}

//       <div>
//         {/* Only render PublicFormPreview if formId is available */}
//         {applicationData.formId ? (
//           <PublicFormPreview
//             formId={applicationData.formId}
//             pipelineId={applicationData.pipelineId}
//           />
//         ) : (
//           <p>Loading form...</p>
//         )}
//       </div>
//     </div>
//   );
// };

// export default FinalApplicationForm;

//////////bef fetch poster 13 09

// import React, { useState, useEffect } from "react";
// import { useParams } from "react-router-dom";
// import axios from "axios";
// import PublicFormPreview from "../Form/PublicFormPreview.jsx";
// import "./FinalApplicationForm.css";

// const FinalApplicationForm = () => {
//   const { id } = useParams();
//   const [applicationData, setApplicationData] = useState({});
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchApplicationData = async () => {
//       try {
//         const pipelineResponse = await axios.get(
//           `https://mern3-9rgs.onrender.com/api/pipelines/${id}`
//         );
//         const pipeline = pipelineResponse.data;

//         console.log("Pipeline Data:", pipeline);

//         const formId = pipeline.forms; // Assuming `forms` is directly a string
//         const pipelineId = pipeline._id; // Added to get pipelineId

//         setApplicationData({
//           title: pipeline.applicationTitle,
//           poster:
//             pipeline.poster.length > 0
//               ? `https://mern3-9rgs.onrender.com/${pipeline.poster[0].url}`
//               : "",
//           description: pipeline.description,
//           documents: pipeline.supportingDocuments,
//           formId: formId, // Ensure the correct formId is set
//           pipelineId: pipelineId, // Ensure the correct pipelineId is set
//         });

//         setLoading(false);
//       } catch (error) {
//         console.error("Error fetching application data:", error);
//         setError(error);
//         setLoading(false);
//       }
//     };

//     fetchApplicationData();
//   }, [id]);

//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   if (error) {
//     return <div>Error loading data: {error.message}</div>;
//   }

//   // Log formId to verify it's correctly set
//   console.log(
//     "Form ID being passed to PublicFormPreview:",
//     applicationData.formId
//   );
//   console.log(
//     "Pipeline ID being passed to PublicFormPreview:",
//     applicationData.pipelineId
//   );

//   return (
//     <div className="application-form-containerfinalapplication">
//       <h1 className="application-titlefinalapplication">
//         {applicationData.title}
//       </h1>
//       {applicationData.poster && (
//         <img
//           src={applicationData.poster}
//           alt="Application Poster"
//           className="application-posterfinalapplication"
//         />
//       )}
//       <p className="application-descriptionfinalapplication">
//         {applicationData.description}
//       </p>

//       {/* <div className="supporting-documentsfinalapplication">
//         <h3>Supporting Documents</h3>
//         <ul>
//           {applicationData.documents.map((doc, index) => (
//             <li key={index}>
//               <a href={`http://localhost:5000/${doc.url}`} target="_blank" rel="noopener noreferrer">
//                 {doc.name}
//               </a>
//             </li>
//           ))}
//         </ul>
//       </div> */}

//       {/* START CHANGE Supporting Documents --- */}
//       {applicationData.documents && applicationData.documents.length > 0 && (
//         <div className="supporting-documentsfinalapplication">
//           <h2>Supporting Documents</h2>
//           <ul>
//             {applicationData.documents.map((doc, index) => (
//               <li key={index}>
//                 <a
//                   href={`https://mern3-9rgs.onrender.com/${doc.url}`}
//                   target="_blank"
//                   rel="noopener noreferrer"
//                 >
//                   {doc.name}
//                 </a>
//               </li>
//             ))}
//           </ul>
//         </div>
//       )}
//       {/* END CHANGE FOR Supporting Documents--- */}

//       <div>
//         {/* Only render PublicFormPreview if formId is available */}
//         {applicationData.formId ? (
//           <PublicFormPreview
//             formId={applicationData.formId}
//             pipelineId={applicationData.pipelineId}
//           />
//         ) : (
//           <p>Loading form...</p>
//         )}
//       </div>
//     </div>
//   );
// };

// export default FinalApplicationForm;
