import React, { useState, useEffect } from "react";
import { FaBell } from "react-icons/fa";
import { RiArrowDropDownLine } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import { CgNotes } from "react-icons/cg";
import { AiOutlineEye } from "react-icons/ai";
import { IoHomeOutline } from "react-icons/io5";
import { GrDocumentSound } from "react-icons/gr";
import { IoIosLink } from "react-icons/io";
import { TbUsersGroup } from "react-icons/tb";
import "./Homepage.css";

const Homepage = () => {
  const [user, setUser] = useState({ name: "", email: "", username: "" }); // State for user data
  const [cohortsCount, setCohortsCount] = useState(0); // State to store cohorts count
  const [cohortsList, setCohortsList] = useState([]); // State to store the list of cohorts
  const [applicationsCount, setApplicationsCount] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    fetchUserData(); // Fetch user data on component mount
    fetchCohortsCount(); // Fetch cohorts count on component mount
    fetchCohortsList(); // Fetch cohorts list on component mount
  }, []);

  // HIGHLIGHT START: Function to fetch user data
  const fetchUserData = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        "https://mern3-9rgs.onrender.com/api/programmanagers/me",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const userData = await response.json();
        setUser(userData);
      } else {
        console.error("Failed to fetch user data. Status:", response.status);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };
  // HIGHLIGHT END
  // *** START CHANGE FOR total Cohorts count ***
  const fetchCohortsCount = async () => {
    try {
      const response = await fetch(
        "https://mern3-9rgs.onrender.com/api/cohorts/total/count"
      ); // Fetch the total number of cohorts
      if (response.ok) {
        const data = await response.json();
        setCohortsCount(data.count); // Set the count in state
      } else {
        console.error(
          "Failed to fetch cohorts count. Status:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error fetching cohorts count:", error);
    }
  };
  // *** END CHANGE FOR total Cohorts count ***
  // *** START CHANGE FOR cohorts list ***
  const fetchCohortsList = async () => {
    try {
      const response = await fetch(
        "https://mern3-9rgs.onrender.com/api/cohorts"
      ); // Fetch the list of cohorts
      if (response.ok) {
        const data = await response.json();
        setCohortsList(data); // Set the list in state
        // Call fetchApplicationsCounts once cohortsList is set
        fetchApplicationsCounts(data);
      } else {
        console.error("Failed to fetch cohorts list. Status:", response.status);
      }
    } catch (error) {
      console.error("Error fetching cohorts list:", error);
    }
  };
  // *** END CHANGE FOR cohorts list ***

  // *** START CHANGE for GET all response 'Grand Total count' under particular cohort ***
  const fetchApplicationsCounts = async (cohorts) => {
    const counts = {};
    for (const cohort of cohorts) {
      const response = await fetch(
        `https://mern3-9rgs.onrender.com/api/forms/${cohort._id}/pipelines/grandtotalresponses`
      );
      if (response.ok) {
        const data = await response.json();
        counts[cohort._id] = data.grandTotalCount;
      }
    }
    setApplicationsCount(counts); // Store all counts in state
  };
  // *** END CHANGE for GET all response 'Grand Total count' under particular cohort ***
  // *** START CHANGE for applications 'Grand Total ' ***
  const calculateTotalApplications = () => {
    return Object.values(applicationsCount).reduce(
      (total, count) => total + count,
      0
    );
  };
  // *** END CHANGE for applications 'Grand Total ' ***

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  return (
    <div className="dashboard-homepage">
      <aside className="sidebar-homepage">
        <div className="logo-container-homepage">
          <div className="logo-homepage">
            <img
              src="/navbar/drishtilogo.jpg"
              alt="Logo"
              className="dristilogo-homepage"
            />
          </div>
        </div>
        <div className="nav-container-homepage">
          <nav className="nav-homepage">
            <ul>
              <li>
                <Link to="/homepage">
                  <IoHomeOutline className="nav-icon-homepage" /> Homepage
                </Link>
              </li>
              <li>
                <Link to="/cohorts">
                  <GrDocumentSound className="nav-icon-homepage" /> Cohorts
                </Link>
              </li>
              <li>
                <Link to="/pipeline">
                  <IoIosLink className="nav-icon-homepage" /> Pipeline
                </Link>
              </li>
              <li>
                <Link to="/form">
                  <CgNotes className="nav-icon-homepage" /> Create Query Form
                </Link>
              </li>
              <li>
                <Link to="/evaluator-dashboard">
                  <AiOutlineEye className="nav-icon-homepage" /> Create
                  Evaluation Form
                </Link>
              </li>
              <li>
                <Link to="/applications">
                  <TbUsersGroup className="nav-icon-homepage" /> Applications
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </aside>
      <main className="main-content-homepage">
        <header className="header-homepage">
          <span className="founder-homepage">All Forms</span>
          <div className="profile-section-homepage">
            <div className="user-info-homepage">
              <span className="user-initials-homepage">
                <img
                  src="/navbar/login.png"
                  alt="Login"
                  style={{ width: "40px" }}
                />
              </span>
              <div className="user-details-homepage">
                {/* HIGHLIGHT START: Displaying fetched username and email */}
                <span className="user-name-homepage">{user.username}</span>
                <br />
                <span className="user-email-homepage">{user.email}</span>
                {/* HIGHLIGHT END */}
              </div>
            </div>
            <button
              className="logout-button-homepage"
              onClick={handleLogout} // Ensure this function is defined in your component
              style={{ marginLeft: "20px", padding: "8px" }} // Add any additional styling as needed
            >
              Logout
            </button>
          </div>
        </header>
        <section className="content-homepage">
          <div className="dashboard-cards-homepage">
            <div className="card-homepage">
              <h4>
                <Link
                  to="/cohorts"
                  className="card-ohorts-count-link-to-cohorts-page-homepage"
                >
                  Cohorts
                </Link>
              </h4>
              <div className="count-homepage-numbers">{cohortsCount}</div>
            </div>
            <div className="card-homepage">
              <h4>Applications</h4>
              <div className="count-homepage"></div>
              <div className="count-homepage-numbers">
                {calculateTotalApplications()}
              </div>
            </div>
          </div>

          <div className="table-container-homepage">
            <h3>Fellowship Program</h3>
            <table className="program-table-homepage">
              <thead>
                <tr>
                  <th>Cohort</th>
                  <th>Applications</th>
                  {/* <th>Startups</th> */}
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {/* *** START CHANGE FOR cohorts list display *** */}
                {cohortsList.length > 0 ? (
                  cohortsList.map((cohort) => (
                    <tr key={cohort._id}>
                      <td>{cohort.name}</td>
                      <td>{applicationsCount[cohort._id] || 0}</td>
                      <td> </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3">No cohorts available</td>
                  </tr>
                )}
                {/* *** END CHANGE FOR cohorts list display *** */}
              </tbody>
              {/* *** START CHANGE for applications 'Grand Total ' *** */}
              <tfoot className="total-cohort-count-homepage">
                <tr>
                  <td>
                    <strong>Total</strong>
                  </td>
                  <td>
                    <strong>{calculateTotalApplications()}</strong>
                  </td>
                  <td></td>
                </tr>
              </tfoot>
              {/* *** END CHANGE for applications 'Grand Total ' *** */}
            </table>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Homepage;

